import Solicitud from '../async/solicitud';

const HookCertificaciones = async(id,rfc,certificaciones) => {
    if( certificaciones !== null){
        let cert = new Solicitud("POST", certificaciones, 0, "docs.php/certificaciones");
        await cert.upload( rfc );
        //TODO: Guardamos la ruta de las certificaciones
        let valorPath ='/certificaciones/'+rfc+'.pdf';
        let array = {key: id, atributo:'certificacionesPath', valor:valorPath}
        let solicitud = new Solicitud("PUT", array, 1, "empleado_documentos.php");
        return await solicitud.exec();
    }
  return true;
}

export default HookCertificaciones;