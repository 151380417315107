import React from 'react';
import CardSolicitud from '../components/Cards/CardSolicitud';
import FormularioVacaciones from '../components/Forms/FormularioVacaciones';
import ModalCarga from '../components/Modal/ModalCarga';
import ModalConfirmacion from '../components/Modal/ModalConfirmacion';
import ModalError from '../components/Modal/ModalError';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';

const Solicitud = ({
    onSubmit, 
    espera, 
    show, 
    title, 
    body, 
    onClose, 
    loading, 
    solicitudes, 
    info, 
    changeItem, 
    changeItemE, 
    item, 
    itemE, 
    onChange, 
    form,
    confirmation,
    onCloseConfirmation,
    callBack,
    onConfirmation
}) =>(
    <div className="container-principal">
        <ModalCarga show={loading} />
        <ModalError mostrar={show} title={title} mensaje={body} ocultarModal={onClose} />
        <ModalConfirmacion mostrar={confirmation} title={title} body={body} callBack={callBack} ocultarModal={onCloseConfirmation} />
        <div className="info-container">
            <div className="row justify-content-center">
                <div className="mt-4 col-lg-4 text-center">
                    <h3>Días disponibles de vacaciones</h3>
                    <div className="content-center mb-4">
                        <div className="line-title" />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="days-avatar">
                            <p>{Math.trunc( info.disponibles )}</p>
                            <p>días</p>
                        </div>
                    </div>
                    {
                        solicitudes.length > 0 ?
                            <div className="mt-4">
                                <h5>Última solicitud</h5>
                                <div className="content-center mb-4">
                                    <div className="line-title" />
                                </div>
                                <div className="iterator-container d-flex mt-4 mb-4 flex-row justify-content-center align-items-center">
                                    <i id="1" onClick={changeItem} className="fas fa-chevron-left mr-4"></i>
                                    <p>{item + 1} de {solicitudes.length}</p>
                                    <i id="0" onClick={changeItem} className="fas fa-chevron-right ml-4"></i>
                                </div>
                                <CardSolicitud solicitud={solicitudes[item]} />
                            </div>
                            :
                            <div className="b-sol mt-4">
                                <p>No tienes solicitudes registradas</p>
                            </div>
                    }
                </div>
                <div className="mt-4 col-lg-4">
                    <h3 className="text-center">Crear nueva solicitud</h3>
                    <div className="content-center mb-5">
                        <div className="line-title" />
                    </div>
                    <FormularioVacaciones handleSubmit={onSubmit} form={form} onChange={onChange} />
                </div>
                <div className="col-lg-4">
                    {
                        espera.length > 0 ?
                            <div className="mt-4">
                                <h3 className="text-center">Solicitudes pendientes</h3>
                                <div className="content-center mb-4">
                                    <div className="line-title" />
                                </div>
                                <div className="iterator-container d-flex mt-4 mb-4 flex-row justify-content-center align-items-center">
                                    <i id="1" onClick={changeItemE} className="fas fa-chevron-left mr-4"></i>
                                    <p>{itemE + 1} de {espera.length}</p>
                                    <i id="0" onClick={changeItemE} className="fas fa-chevron-right ml-4"></i>
                                </div>
                                <CardSolicitud onClick={onConfirmation} flag={true} solicitud={espera[itemE]} />
                            </div>
                            :
                            <div className="b-sol mt-4">
                                <p>No tienes solicitudes por aprobar</p>
                            </div>
                    }
                </div>
            </div>
        </div>
        <NavRHEA />
        <Notification />
    </div>
);

export default Solicitud;