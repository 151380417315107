import React, { Fragment } from 'react';
import CardSolicitud from '../components/Cards/CardSolicitud';
import ModalClave from '../components/Modal/ModalClave';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';

const Index = ({solicitudes, modal, onHide, info, id, to}) => (
    <Fragment>
        <ModalClave mostrar={modal} ocultarModal={onHide} id={id} />
        <div className="container-principal">
            <NavRHEA />
            <Notification />
            <div className="info-container">
                <div className="row">
                    <div className="col-md-4 mt-4 text-center">
                        <h3>Vacaciones</h3>
                        <div className="content-center mb-4">
                            <div className="line-title" />
                        </div>
                        <div className="d-flex justify-content-center">
                                <div className="days-avatar">
                                    <p>{info.disponibles}</p>
                                    <p>días</p>
                                </div>
                        </div>
                        <button onClick={to} name="solicitudes" className="mt-3 btn-rhea-alternative">Vacaciones</button>
                        {
                            solicitudes.length > 0 ?
                                <div className="mt-4">
                                    <h5>Última solicitud</h5>
                                    <div className="content-center mb-4">
                                        <div className="line-title" />
                                    </div>
                                    <CardSolicitud solicitud={solicitudes[0]} />
                                </div>
                            :
                                <div className="b-sol mt-4">
                                    <p>No tienes solicitudes registradas</p>
                                </div>
                        }
                    </div>
                    <div className="col-md-4 mt-4">
                        <h3 className="text-center">Directorio</h3>
                        <div className="content-center mb-4">
                            <div className="line-title" />
                        </div>
                        <div className="text-center">
                            <i className="icon-index fas fa-address-book"></i>
                        </div>
                        <div className="text-center">
                            <button className="mt-4 btn-rhea-alternative">Ver el directorio</button>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4">
                        <h3 className="text-center">Asistencia</h3>
                        <div className="content-center mb-4">
                            <div className="line-title" />
                        </div>
                        <div className="container">
                            <div className="container mb-3 text-center">
                                <i className="fas icon-index fa-calendar-alt"></i>
                            </div>
                            <h4 className="text-center">Próximamente</h4>
                        </div>
                        <h3 className="text-center mt-5">Anuncios</h3>
                        <div className="content-center mb-4">
                            <div className="line-title" />
                        </div>
                        <div className="container">
                            <h5 className="text-center">¡Te damos la bienvenido!</h5>
                            <p className="text-justify mt-4">A través de la plataforma podrás solicitar tus vacaciones, actualizar tu información personal y obtener la información de contacto de las personas dentro de la organización.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>
);
export default Index;