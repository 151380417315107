import React from 'react';
import DateFormatFix from '../../utils/DateFormatFix';
import AvatarUser from '../Others/AvatarUser';

const Profile = ({user, contact, work, foto, changePhoto, address }) => {

    return (
        <div className="">
            <div className="row">
                <div className="text-center col-md-4">
                    <div className="row justify-content-center">
                        <AvatarUser className="avatar-perfil" path={foto} name={user.nombre} />
                    </div>
                    <div className="text-center mt-4">
                        <label htmlFor="foto">
                            <span className="btn-rhea-alternative">Cambiar Foto</span>
                        </label>
                        <input onChange={changePhoto} className="d-none" id="foto" type="file" />
                    </div>
                    <div className="text-center mt-2">
                        <button className="btn-rhea-alternative">Cambiar Contraseña</button>
                    </div>
                </div>
                <div className="text-center mt-4 col-md-4">
                    <h3>Información General</h3>
                    <div className="content-center mb-4">
                        <div className="line-title" />
                    </div>
                    <p>ID: {user.idPersonal ? user.idPersonal : "No definido"}</p>
                    <p>Nombre: {user.nombre ? user.nombre : ""} {user.apellidoPaterno ? user.apellidoPaterno : ""} {user.apellidoMaterno ? user.apellidoMaterno : ""}</p>
                    <p>CURP: {user.curp ? user.curp : ""}</p>
                    <p>Fecha de nacimiento: {user.fechaDeNacimiento ? new DateFormatFix(new Date(user.fechaDeNacimiento + "T00:00:00")).getFormatName() : ""}</p>
                    <p>Estado civil: {user.estadoCivil ? user.estadoCivil : ""}</p>
                    <h3 className="mt-3">Información de contacto</h3>
                    <div className="content-center mb-4">
                        <div className="line-title" />
                    </div>
                    <p>Teléfono: {contact.telefono ? contact.telefono : "No proporcionado"}</p>
                    <p>Celular: {contact.celular ? contact.celular : "No proporcionado"}</p>
                    <p>Correo Personal: {contact.correo ? contact.correo : "No proporcionado"}</p>
                </div>
                <div className="text-center mt-4 col-md-4">
                    <h3>Información laboral</h3>
                    <div className="content-center mb-4">
                        <div className="line-title" />
                    </div>
                    <p>Puesto: {work.puesto ? work.puesto : "No proporcionado"}</p>
                    <p>Fecha Ingreso: {work.fechaIngreso ? new DateFormatFix( new Date( work.fechaIngreso ) ).getFormatName() : "No proporcionado"}</p>
                    <p>Jefe: {work.nombreJefe ? work.nombreJefe : "No proporcionado"}</p>
                    <p>Correo Empresarial: {work.correoEmpresarial ? work.correoEmpresarial : "No proporcionado"}</p>
                    <h3 className="mt-3">Información de domicilio</h3>
                    <div className="content-center mb-4">
                        <div className="line-title" />
                    </div>
                    <p>Estado: {address.estado}</p>
                    <p>Municipio: {address.municipio}</p>
                    <p>Dirección: {address.calle} Int. {address.interior} Ext. {address.exterior} {address.codigoPostal}, {address.colonia}</p>
                </div>
                <div className="text-center col-md-3">
                </div>
            </div>
        </div>
    )
};

export default Profile;