import React from 'react';

const Steps = ({step, onClick}) => {
    return (
        <div className="d-flex flex-wrap justify-content-center mt-5">
            <div className="pr-3 pl-3">
                <div id="1" onClick={onClick} className={ step === 1 ? "step-select" : "container-step" }>1</div>
                <p className="text-step">Datos generales</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="2" onClick={onClick} className={ step === 2 ? "step-select" : "container-step"  }>2</div>
                <p className="text-step">Datos contacto</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="3" onClick={onClick} className={ step === 3 ? "step-select" : "container-step"  }>3</div>
                <p className="text-step">Datos domicilio</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="4" onClick={onClick} className={ step === 4 ? "step-select" : "container-step"  }>4</div>
                <p className="text-step">Datos bancarios</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="5" onClick={onClick} className={ step === 5 ? "step-select" : "container-step"  }>5</div>
                <p className="text-step">Contactos emergencia</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="6" onClick={onClick} className={ step === 6 ? "step-select" : "container-step"  }>6</div>
                <p className="text-step">Documentos</p>
            </div>
            <div className="pr-3 pl-3">
                <div id="7" onClick={onClick} className={ step === 7 ? "step-select" : "container-step"  }>7</div>
                <p className="text-step">Resumen</p>
            </div>
        </div>
    )
};

export default Steps;