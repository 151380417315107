import React, { Fragment, useEffect, useState } from 'react';
import Request from '../../async/request';
import DateFormatFix from '../../utils/DateFormatFix';

const SectionWork = ({ idPersonal }) => {
    const [work, setWork] = useState({});
    const [address, setAddress] = useState({});

    useEffect(
        () =>{
            async function get() {
                let request = new Request("GET", null, true, "control/empleado_laboral/find/" + idPersonal );
                let response = await request.exec();
                console.log( response );
                if( response.isSuccess )
                    if( response.data !== null )
                        setWork( response.data );
                request = new Request("GET", null, true, "control/empleado_domicilio/find/" + idPersonal );
                response = await request.exec();
                if( response.isSuccess )
                    setAddress( response.data );
            }
            get();
        },[idPersonal]
    )

    return (
        <Fragment>
            <div className="text-center mt-4 col-md-4">
                <h3>Información laboral</h3>
                <div className="content-center mb-4">
                    <div className="line-title" />
                </div>
                <p>Puesto: {work.puesto ? work.puesto : "No proporcionado"}</p>
                <p>Fecha Ingreso: {work.fechaIngreso ? new DateFormatFix( new Date( work.fechaIngreso ) ).getFormatName() : "No proporcionado"}</p>
                <p>Jefe: {work.nombreJefe ? work.nombreJefe : "No proporcionado"}</p>
                <p>Correo Empresarial: {work.correoEmpresarial ? work.correoEmpresarial : "No proporcionado"}</p>
                <h3 className="mt-3">Información de domicilio</h3>
                <div className="content-center mb-4">
                    <div className="line-title" />
                </div>
                <p>Estado: {address.estado}</p>
                <p>Municipio: {address.municipio}</p>
                <p>Dirección: {address.calle} Int. {address.interior} Ext. {address.exterior} {address.codigoPostal}, {address.colonia}</p>
            </div>
        </Fragment>
    )
};

export default SectionWork;