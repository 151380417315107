class DateFormatFix{
    constructor(dateType, day, month, year, hour, min){
        if( dateType === null ){
            this.day = day;
            this.month = month;
            this.year = year;
            this.hour = hour;
            this.min = min;
        }
        else{
            this.day = dateType.getDate();
            this.month = dateType.getMonth();
            this.year = dateType.getFullYear();
            this.hour = dateType.getHours();
            this.min = dateType.getMinutes();
        }
    }

    getDate(){
        let day = this.day
        day =  day > 9 ? day : "0" + day;
        let month = this.month + 1
        month = month > 9 ? month : "0" + month;
        return this.year + "-" + month + "-" + day 
    }
    getDateTime(){
        return this.getDate() + " " + this.hour + ":" + this.min + ":00";
    }
    getFormat(){
        let hr, min, d, m;
        this.hour > 9 ? hr = this.hour : hr = "0" + this.hour;
        this.min > 9 ? min = this.min : min = "0" + this.min;
        this.day > 9 ? d = this.day : d = "0" + this.day;
        this.month + 1 > 9 ? m = this.month + 1 : m = "0" + (this.month + 1);
        return d + "/" + (m) + "/" + this.year + " " + hr + ":" + min;
    }
    getFormatMin(){
        return ( this.day + 1 )+ "/" + (this.month + 1) + "/" + this.year;
    }
    getFormatFol(){
        let day = this.day + 1
        day =  day > 9 ? day : "0" + day;
        let month = this.month + 1
        month = month > 9 ? month : "0" + month;
        return  day + "" + month + "" + String( this.year ).substring(2,4);
    }
    getFormatFolio(){
        let date = this.getFormatFol();
        let min = this.min;
        min = min > 9 ? min : "0" + min;
        let hour = this.hour;
        hour = hour > 9 ? hour : "0" + hour;
        date = date + "-" + hour + "" + min;
        return date;
    }
    getFormatName(){
        let monthName = ""
        switch( this.month ){
            case 0 : monthName = "Enero"; break;
            case 1 : monthName = "Febrero"; break;
            case 2 : monthName = "Marzo"; break;
            case 3 : monthName = "Abril"; break;
            case 4 : monthName = "Mayo"; break;
            case 5 : monthName = "Junio"; break;
            case 6 : monthName = "Julio"; break;
            case 7 : monthName = "Agosto"; break;
            case 8 : monthName = "Septiembre"; break;
            case 9 : monthName = "Octubre"; break;
            case 10 : monthName = "Noviembre"; break;
            case 11 : monthName = "Diciembre"; break;
            default : monthName = "Not found"
        }
        return ( this.day ) + " de " + monthName + " " + String( this.year ) ;
    }
    getFormatNameFix(){
        let monthName = ""
        switch( this.month ){
            case 0 : monthName = "Enero"; break;
            case 1 : monthName = "Febrero"; break;
            case 2 : monthName = "Marzo"; break;
            case 3 : monthName = "Abril"; break;
            case 4 : monthName = "Mayo"; break;
            case 5 : monthName = "Junio"; break;
            case 6 : monthName = "Julio"; break;
            case 7 : monthName = "Agosto"; break;
            case 8 : monthName = "Septiembre"; break;
            case 9 : monthName = "Octubre"; break;
            case 10 : monthName = "Noviembre"; break;
            case 11 : monthName = "Diciembre"; break;
            default : monthName = "Not found"
        }
        return ( this.day ) + " de " + monthName + " " + String( this.year ) ;
    }
}

export default DateFormatFix;