import React, {Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'

const ModalCarga = ({show, cancelar}) => (
    <Fragment>
        <Modal show={show} className="m-body" onHide={cancelar} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="m-body">
                    <Spinner animation="border" />
                </Modal.Body>
        </Modal>
    </Fragment>
)

export default ModalCarga;