import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import INE from '../../images/ine.png';


const ModalINE = ({ mostrar, ocultarModal }) => {
    return (
        <Fragment>
            <Modal
                show={mostrar}
                onHide={ocultarModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="flag-modal"
            >
                <div className="transparent">
                    <img src={INE} style={{ width: '100%', borderRadius: '50px' }} alt="ine-example" />
                </div>
            </Modal>
        </Fragment>
    )
}

export default ModalINE;