import React, { useEffect, useState } from 'react';
import Request from '../../async/request';

const SectionDocumentos = ({ idPersonal, descargar }) => {
    const [documentos, setDocumentos] = useState(null);

    useEffect(
        () => {
            async function get() {
                let request = new Request("GET", null, 1, "control/empleado_documentos/find/" + idPersonal);
                let response = await request.exec();
                console.log("DOCUMENTOS:");
                console.log(response);
                setDocumentos(response.data);
            }
            get()
        }, [idPersonal]
    )

    return (
        <div className="col-md-8 mt-4">
            {documentos !== null ?
                <div className="row justify-content-center documentos">
                    {documentos.cv ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.cv, "CV")} className="btn-rhea-alternative">Descargar CV</button>
                        </div>
                        : void (0)}
                    {documentos.curpPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.curpPath, "CURP")} className="btn-rhea-alternative">Descargar CURP</button>
                        </div>
                        : void (0)}
                    {documentos.actaPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.actaPath, "ACTA")} className="btn-rhea-alternative">Descargar Acta de nacimiento</button>
                        </div>
                        : void (0)}
                    {documentos.comprobanteDomicilio ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.comprobanteDomicilio, "DOM")} className="btn-rhea-alternative">Descargar Comprobante de Domiclio</button>
                        </div>
                        : void (0)}
                    {documentos.INEPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.INEPath, "ID")} className="btn-rhea-alternative">Descargar Identificación</button>
                        </div>
                        : void (0)}
                    {documentos.nssPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.nssPath, "NSS")} className="btn-rhea-alternative">Descargar NSS</button>
                        </div>
                        : void (0)}
                    {documentos.rfcPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.rfcPath, "RFC")} className="btn-rhea-alternative">Descargar RFC</button>
                        </div>
                        : void (0)}
                    {documentos.estudiosPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.estudiosPath, "EST")} className="btn-rhea-alternative">Descargar Comprobante de estudios</button>
                        </div>
                        : void (0)}
                    {documentos.recomendacionPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.recomendacionPath, "CAR")} className="btn-rhea-alternative">Descargar Cartas de recomendación</button>
                        </div>
                        : void (0)}
                    {documentos.INFONAVITPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.INFONAVITPath, "INF")} className="btn-rhea-alternative">Descargar INFONAVIT</button>
                        </div>
                        : void (0)}
                    {documentos.FONACOTPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.FONACOTPath, "FON")} className="btn-rhea-alternative">Descargar FONACOT</button>
                        </div>
                        : void (0)}
                    {documentos.estadoCuentaPath ?
                        <div className="col-md-3 mt-2">
                            <button onClick={() => descargar(documentos.estadoCuentaPath, "estadoCuenta")} className="btn-rhea-alternative">Descargar Estado de Cuenta</button>
                        </div>
                        : void (0)}
                </div>
                :
                void (0)
            }
        </div>
    )
};

export default SectionDocumentos;