import { FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import React, { Fragment } from 'react'
import useStyles from '../../theme/ThemeInput';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';

const FormularioVacaciones = ({ form, onChange, handleSubmit, loading }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Form onSubmit={handleSubmit} className="form-container">

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="tipo-vacaciones">
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel>Tipo</InputLabel>
                                <Select native label="Tipo" required as="select" name="tipo" value={form.tipo} onChange={onChange}>
                                    <option value=""></option>
                                    <option>Vacaciones</option>
                                    <option>Permiso sin goce de sueldo</option>
                                    <option>Permiso con goce de sueldo</option>
                                    <option>Home office</option>
                                    <option>Falta</option>
                                    <option>Incapacidad</option>
                                </Select>
                            </FormControl>
                        </Form.Group>
                    </div>
                    <div className="col-lg-6">
                        <Form.Group controlId="vacaciones-fecha-inicio">
                            <TextField className={classes.textField} variant="outlined" label="Inicio"
                                required type="date" name="inicio" value={form.inicio} onChange={onChange} />
                        </Form.Group>
                    </div>
                    <div className="col-lg-6">
                        <Form.Group controlId="vacaciones-fecha-inicio">
                            <TextField className={classes.textField} variant="outlined" label="Fin"
                                required type="date" name="fin" value={form.fin} onChange={onChange} />
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <Form.Group controlId="vacaciones-fecha-inicio">
                            <TextField className={classes.textField} variant="outlined" label="Autorizador"
                                required type="text" disabled name="autorizador" value={form.autorizador} onChange={onChange} />
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group controlId="vacaciones-fecha-inicio">
                            <TextField disabled className={classes.textField} variant="outlined" label="Días"
                                required type="number" name="dias" value={form.dias} onChange={onChange} />
                        </Form.Group>
                    </div>
                </div>

                <div className="text-right">
                    {loading ?
                        <Spinner className="mr-3 mt-2" animation="grow" />
                        :
                        <button className="btn-rhea-alternative" type="submit">
                            Enviar
                        </button>
                    }
                </div>
            </Form>
        </Fragment>
    )
}

export default FormularioVacaciones;