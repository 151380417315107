import { TextField, Fab, FormControl, InputLabel, Select } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import useStyles from '../../theme/ThemeInput';
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const FormularioDireccion = ({btnText="Enviar", form, colonias, onChange, onChangeAttrib, handleSubmit }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <div className="container-form-rhea">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group controlId="direccion-cp">
                                <TextField className={classes.textField} variant="outlined" label="Código postal" inputProps={{ maxLength: 6 }} required type="text" name="codigoPostal" value={form.codigoPostal} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="direccion-estado">
                                <TextField className={classes.textField} variant="outlined" label="Estado" required disabled type="text" name="estado" onChange={onChangeAttrib} value={form.estado} placeholder="Estado" />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="direccion-municipio">
                                <TextField className={classes.textField} variant="outlined" label="Municipio" required disabled type="text" value={form.municipio} placeholder="Municipio" />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="direccion-colonia">
                                <FormControl className={classes.textField} variant="outlined">
                                    <InputLabel>Colonia</InputLabel>
                                    <Select native label="Colonia" required name="colonia" value={form.colonia} onChange={onChangeAttrib}>
                                        <option />
                                        {colonias.map(
                                            (colonia, i) =>
                                            (
                                                <option key={i}>{colonia.toUpperCase()}</option>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="direccion-calle">
                                <TextField className={classes.textField} variant="outlined" label="Calle" inputProps={{ maxLength: 100 }} required type="text" value={form.calle} name="calle" onChange={onChangeAttrib} />
                            </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <Form.Group controlId="direccion-exterior">
                                <TextField className={classes.textField} variant="outlined" label="Exterior" inputProps={{ maxLength: 20 }} required type="text" value={form.numeroExterior} name="numeroExterior" onChange={onChangeAttrib} />
                            </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <Form.Group controlId="direccion-interior">
                                <TextField className={classes.textField} variant="outlined" label="Interior" inputProps={{ maxLength: 20 }} type="text" value={form.numeroInterior} name="numeroInterior" onChange={onChangeAttrib} placeholder="Ingrese el número interior" />
                            </Form.Group>
                        </div>
                    </div>
                    
                    <div className="mt-4 text-center">
                        <Fab type="submit" className="button-send" variant="extended">
                            <NavigationIcon />
                            {btnText}
                        </Fab>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default FormularioDireccion;