import Solicitud from '../async/solicitud';

const hookDocumentos = async (
    id,
    form,
    rfc,
    curp,
    acta,
    nss,
    ine,
    comprobante,
    estudios,
    cv,
    infonavit,
    fonacot,
    recomendacion,
    estadoCuenta
) => {
    let rfcPath = "";
    let curpPath = "";
    let nssPath = "";
    let inePath = "";
    let comprobantePath = "";
    let cvPath = "";
    let infonavitPath = "";
    let fonacotPath = "";
    let actaPath = "";
    let estudiosPath = "";
    let recomendacionPath = "";
    let estadoCuentaPath = "";
    let solicitud;

    if (rfc != null) {
        solicitud = new Solicitud("POST", rfc, 0, "docs.php/rfc");
        let dataRFC = await solicitud.upload( form.rfc );
        if (dataRFC.esExitosa)
            rfcPath = dataRFC.datos;
    }
    if (acta != null) {
        solicitud = new Solicitud("POST", acta, 0, "docs.php/acta"); 
        let dataActa = await solicitud.upload( form.rfc );
        if (dataActa.esExitosa)
            actaPath = dataActa.datos;
    }
    if (estudios != null) {
        solicitud = new Solicitud("POST", estudios, 0, "docs.php/estudios");
        let dataEstudios = await solicitud.upload( form.rfc );
        if (dataEstudios.esExitosa)
            estudiosPath = dataEstudios.datos;
    }
    if (curp != null) {
        solicitud = new Solicitud("POST", curp, 0, "docs.php/curp");
        let dataCURP = await solicitud.upload( form.curp );
        if (dataCURP.esExitosa)
            curpPath = dataCURP.datos;
    }
    if (nss != null) {
        solicitud = new Solicitud("POST", nss, 0, "docs.php/nss");
        let dataNSS = await solicitud.upload( form.rfc );
        if (dataNSS.esExitosa)
            nssPath = dataNSS.datos;
    }
    if (ine != null) {
        solicitud = new Solicitud("POST", ine, 0, "docs.php/ine");
        let dataINE = await solicitud.upload( form.rfc );
        if (dataINE.esExitosa)
            inePath = dataINE.datos;
    }
    if (comprobante != null) {
        solicitud = new Solicitud("POST", comprobante, 0, "docs.php/comprobante");
        let dataComprobante = await solicitud.upload( form.rfc );
        if (dataComprobante.esExitosa)
            comprobantePath = dataComprobante.datos;
    }
    if (cv != null) {
        solicitud = new Solicitud("POST", cv, 0, "docs.php/cv");
        let dataCV = await solicitud.upload( form.rfc );
        if (dataCV.esExitosa)
            cvPath = dataCV.datos; 
    }

    if (infonavit != null) {
        solicitud = new Solicitud("POST", infonavit, 0, "docs.php/infonavit");
        let dataINFONAVIT = await solicitud.exec( form.rfc );
        if (dataINFONAVIT.esExitosa)
            infonavitPath = dataINFONAVIT.datos;
    }
    if (fonacot != null) {
        solicitud = new Solicitud("POST", fonacot, 0, "docs.php/fonacot");
        let dataFONACOT = await solicitud.upload( form.rfc );
        if (dataFONACOT.esExitosa)
            fonacotPath = dataFONACOT.datos;
    }
    if (recomendacion != null) {
        solicitud = new Solicitud("POST", recomendacion, 0, "docs.php/recomendacion");
        let dataRecomendacion = await solicitud.upload( form.rfc );
        if (dataRecomendacion.esExitosa)
            recomendacionPath = dataRecomendacion.datos;
    }
    if(estadoCuenta != null){
        solicitud = new Solicitud("POST", estadoCuenta, 0, "docs.php/estadoCuenta");
        let dataEstadoCuenta = await solicitud.upload( form.rfc );
        if (dataEstadoCuenta.esExitosa)
            estadoCuentaPath = dataEstadoCuenta.datos;
    }

    let body = {
        empleado_documentos: {
            idPersonal: id,
            rfcPath: rfcPath,
            curpPath: curpPath,
            nssPath: nssPath,
            INEPath: inePath,
            comprobanteDomicilio: comprobantePath,
            cv: cvPath,
            INFONAVITPath: infonavitPath,
            FONACOTPath: fonacotPath,
            actaPath: actaPath,
            estudiosPath: estudiosPath,
            recomendacionPath: recomendacionPath,
            estadoCuentaPath: estadoCuentaPath,
            ...form
        }
    }
    solicitud = new Solicitud("POST", body, 1, "empleado_documentos.php");
    return await solicitud.exec();
    
}

export default hookDocumentos;