import React, { useEffect, useState } from 'react';
import Solicitud from '../pages/Solicitud';
import { useHistory } from 'react-router-dom';
import Request from '../async/request';
import DateFormatFix from '../utils/DateFormatFix';

const SolicitudContainer = () =>{
    const history = useHistory();
    const [solicitudes, setSolicitudes] = useState([]);
    const [info, setInfo] = useState({});
    const [item, setItem] = useState(0);
    const [itemE, setItemE] = useState(0);
    const [form, setForm] = useState({
        tipo: '',
        inicio: new DateFormatFix( new Date() ).getDate(), 
        fin: new DateFormatFix( new Date() ).getDate(), 
        fechaSolicitud: new DateFormatFix( new Date() ).getDate(),
        estatus:"Pendiente", 
        dias:'1',
        autorizador:'RECURSOS HUMANOS'
    });
    const [espera, setEspera] = useState({});
    const [laboral, setLaboral] = useState({});
    const [loading, setLoading] = useState(false);
    const [id, setID] = useState("");
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [flag, setFlag] = useState(0);

    useEffect(
        () =>{
            async function fetchUser(){
                let request = new Request("POST", null, 1, "service/valida");
                let response = await request.exec();
                if (response.isSuccess) {
                    let auth = JSON.parse(sessionStorage.getItem("auth"));
                    setID( auth.idPersonal );
                    request = new Request("GET", null, 1, "control/solicitud_vacaciones/id/" + auth.idPersonal);
                    response = await request.exec();
                    if (response.isSuccess)
                        setSolicitudes(response.data);
                    request = new Request("GET", null, 1, "control/solicitud_vacaciones/pendiente/" + auth.idPersonal);
                    response = await request.exec();
                    if (response.isSuccess)
                        setEspera(response.data);
                    request = new Request("GET", null, 1, "control/empleado_laboral/find/" + auth.idPersonal);
                    response = await request.exec();
                    if (response.isSuccess)
                        setLaboral(response.data);
                    let jefeID = response.data.idPersonalJefe;
                    if( jefeID !== null ){
                        request = new Request("GET", null, 1, "control/empleado_general/find/" + jefeID );
                        response = await request.exec();
                        if(response.isSuccess){
                            if( response.data ){
                                setForm({
                                    tipo: '',
                                    inicio: new DateFormatFix( new Date() ).getDate(), 
                                    fin: new DateFormatFix( new Date() ).getDate(), 
                                    fechaSolicitud: new DateFormatFix( new Date() ).getDate(),
                                    estatus:"Pendiente", 
                                    dias:1,
                                    autorizador: response.data.nombre + " " + response.data.apellidoPaterno 
                                });
                            }
                        }
                    }
                    request = new Request("POST", null, 1, "service/vacaciones/" + auth.idPersonal);
                    response = await request.exec();
                    if (response.isSuccess)
                        setInfo(response.data);
                }
                else
                    history.push("/");
            }
            fetchUser();
        },[history]
    );

    const onChange = (e) =>{
        if( e.target.name === "inicio" ){
            if( new Date( form.inicio ) < new Date( e.target.value ) )
                setForm({...form, [e.target.name]:e.target.value, 'fin': e.target.value});
        }
        else if( e.target.name === "fin"){
            let i = new Date( form.inicio );
            let f = new Date( e.target.value );
            if( f >= i ){
                let dias = 0;
                while( f >= i ){
                    i.setDate( i.getDate() + 1  );
                    if( !(i.getDay() === 0 || i.getDay() === 6) )
                        dias ++;
                }
                setForm({...form, [e.target.name]:e.target.value, dias: dias});
            }
            
        }
        else
            setForm({...form, [e.target.name]:e.target.value});
    }

    const changeItem = (e) =>{
        if( e.target.id === "0" ){
            if( !(item + 1 > (solicitudes.length - 1)) )
                setItem( item + 1 );
        }
        else{
            if( item + 1 > 1 )
                setItem( item - 1 );
        }
    }

    const changeItemE = (e) =>{
        if( e.target.id === "0" ){
            if( !(itemE + 1 > (espera.length - 1)) )
                setItemE( itemE + 1 );
        }
        else{
            if( itemE + 1 > 1 )
                setItemE( itemE - 1 );
        }
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        setLoading( true );
        if( form.dias <= info.disponibles || form.tipo !== "Vacaciones" ){
            let body = {solicitud_vacaciones : {...form, idPersonal: id}};
            let request = new Request("POST", body, 1, "control/solicitud_vacaciones" );
            let response = await request.exec();
            if( response.isSuccess ){
                let idSolicitud = response.data[0].idSolicitud;
                request = new Request("GET", null, 1, "control/solicitud_vacaciones/id/" + id);
                response = await request.exec();
                if (response.isSuccess)
                    setSolicitudes(response.data);
                request = new Request("GET", null, 1, "control/empleado_laboral/find/" + laboral.idPersonalJefe );
                response = await request.exec();
                let correo = "";
                if( response.isSuccess ){
                    if( response.data.correoEmpresarial !== "" )
                        correo = "coliveros@atlantisba.com";
                    else
                        correo = "gsanchez@rheach.pro";
                }
                let auth = JSON.parse( sessionStorage.getItem("auth") );
                let body = {
                    correo: correo,
                    nombre: auth.nombre,
                    tipo: form.tipo,
                    inicio: new DateFormatFix( new Date( form.inicio + "T00:00:00" ) ).getFormatName(),
                    fin: new DateFormatFix( new Date( form.fin + "T00:00:00" ) ).getFormatName(),
                    dias: form.dias,
                    idPersonal: id,
                    idSolicitud: idSolicitud
                };
                request = new Request("POST", body, 1, "service/correo/vacaciones");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: id,
                        titulo:"Realizaste una nueva solicitud",
                        mensaje:"Creaste una nueva solicitud de " + form.tipo + " ingresa para conocer el estatus.",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: laboral.idPersonalJefe,
                        titulo: auth.nombre + " realizó una nueva solicitud",
                        mensaje: auth.nombre + " una nueva solicitud de " + form.tipo + " ingresa para dar respuesta.",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                setTitle("¡Tu solicitud fue enviada!");
                setBody("Hemos notificado a tu jefe a su correo para que entre a autorizar tu solicitud. El folio de tu solicitud es: " + idSolicitud );
                setShow(true);
            }
        }
        else{
            setTitle("¡Vaya parece que pasa algo!");
            setBody("No puedes solicitar más días de los que tienes disponibles.");
            setShow(true);
        }

        setLoading( false );
    }

    const onClose = (e) => setShow(false);

    const onCloseConfirmation = () => setConfirmation(false);

    const callBack = async () =>{
        setConfirmation( false );
        setLoading( true );
        let request = new Request("GET", null, 1, "control/empleado_laboral/find/" + espera[itemE].idPersonal );
        let response = await request.exec();
        let correoEmpresarial = response.data.correoEmpresarial;
        request = new Request("GET", null, 1, "control/empleado_general/find/" + espera[itemE].idPersonal );
        response = await request.exec();
        let nombre = response.data.nombre + " " + response.data.apellidoPaterno;
        let body = {
            correo: correoEmpresarial,
            nombre: nombre,
            ...espera[itemE]
        }
        if( flag ){
            let update = {
                idSolicitud: espera[itemE].idSolicitud,
                attribute: 'estatus',
                value: 'Autorizada',
            }
            request = new Request("PUT", update, 1, "control/solicitud_vacaciones" );
            response = await request.exec();
            if( response.isSuccess ){
                request = new Request("POST", body, 1, "service/correo/aceptarVacaciones");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: id,
                        titulo: "Aprobaste una solicitud",
                        mensaje: "Aprobaste la solicitud de " + espera[itemE].tipo + " de " + nombre + ".",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: espera[itemE].idPersonal,
                        titulo: "Aprobaron tu solicitud",
                        mensaje: "Aprobaron tu solicitud de " + espera[itemE].tipo + ".",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                setTitle("Solicitud autorizada");
                setBody("Haz autorizado" + espera[itemE].tipo + " de " + nombre);
            }
            else{
                setTitle("Algo salió mal");
                setBody("Al parecer algo salió mal. Comunícate con el administrador del sistema o intenta más tarde.");
            }
            
        }
        else{
            let update = {
                idSolicitud: espera[itemE].idSolicitud,
                attribute: 'estatus',
                value: 'Rechazada',
            }
            request = new Request("PUT", update, 1, "control/solicitud_vacaciones" );
            response = await request.exec();
            if( response.isSuccess ){
                request = new Request("POST", body, 1, "service/correo/rechazarVacaciones");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: id,
                        titulo: "Rechazaste una solicitud",
                        mensaje: "Rechazaste la solicitud de " + espera[itemE].tipo + " de " + nombre + ".",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                body = {
                    notificacion:{
                        idPersonal: espera[itemE].idPersonal,
                        titulo: "Rechazaron tu solicitud",
                        mensaje: "Rechazaron tu solicitud de " + espera[itemE].tipo + ".",
                        ruta:"solicitudes",
                        visto:0
                    }
                }
                request = new Request("POST", body, 1, "control/notificacion");
                response = await request.exec();
                setTitle("Solicitud rechazada");
                setBody("Haz rechazado  " + espera[itemE].tipo + " de " + nombre);
            }
            else{
                setTitle("Algo salió mal");
                setBody("Al parecer algo salió mal. Comunícate con el administrador del sistema o intenta más tarde.");
            }
        }
        let auth = JSON.parse(sessionStorage.getItem("auth"));
        setItemE(0);
        request = new Request("GET", null, 1, "control/solicitud_vacaciones/pendiente/" + auth.idPersonal);
        response = await request.exec();
        if (response.isSuccess)
            setEspera(response.data);
        setShow( true );
        setLoading(false);
    }

    const onConfirmation = (e) =>{
        let id = parseInt( e.target.name );
        setFlag( id );
        if( id )
            setTitle("¿Deseas aceptar la solicitud?");
        else
            setTitle("¿Deseas rechazar la solicitud?");
        setConfirmation( true );
    } 
    
    return(
        <Solicitud 
            espera={espera}
            show={show}
            title={title}
            body={body}
            onClose={onClose}
            loading={loading} 
            onSubmit={onSubmit} 
            onChange={onChange} 
            form={form} 
            item={item} 
            itemE={itemE}
            changeItem={changeItem} 
            changeItemE={changeItemE} 
            solicitudes={solicitudes} 
            info={info}
            confirmation={confirmation}
            onCloseConfirmation={onCloseConfirmation}
            callBack={callBack}
            onConfirmation={onConfirmation}
        />
    )
};

export default SolicitudContainer;