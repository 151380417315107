import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Request from '../async/request';
import Index from '../pages/Index';

const IndexContainer = () =>{
    const history = useHistory();
    const [solicitudes, setSolicitudes] = useState([]);
    const [info, setInfo] = useState({});
    const [modal, setModal] = useState(false);
    const [id, setID] = useState("");

    useEffect(
        () =>{
            async function fetchUser(){
                let request = new Request("POST", null, 1, "service/valida");
                let response = await request.exec();
                if( response.isSuccess ){
                    let auth = JSON.parse( sessionStorage.getItem("auth") );
                    setID( auth.idPersonal );
                    request = new Request("GET", null, 1, "control/solicitud_vacaciones/id/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess )
                        setSolicitudes( response.data );
                    request = new Request("POST", null, 1, "service/vacaciones/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess )
                        setInfo( response.data );
                    request = new Request("POST",  { id: auth.idPersonal }, 1, "service/usuario/seguridad" );
                    response = await request.exec();
                    if( response.isSuccess )
                        response.data > 365 ? setModal( true ): void(0)
                    
                }
                else
                    history.push("/");
            }
            fetchUser();
        },[history]
    );

    const to = (e) => history.push("/" + e.target.name );

    const onHide = () => setModal( false );

    return(
        <Index modal={modal} id={id} onHide={onHide} to={to} info={info} solicitudes={solicitudes} />
    )
};

export default IndexContainer;