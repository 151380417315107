import React from 'react';
import { TextField, FormControl, InputLabel, Select } from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TabSalario = ({classes, formSalario, onChangeSalarial, ChangeAliasContrato, tipoContratos, selectTipoContrato, ChangeSelectContrato, detalleContratos, ChangeDetalleContratos ,selectDetalleContratos}) => {
  return (
    <div className="mt-4 r-opacity col">
        <form className="row" autoComplete="off">
            <div className="col-md-4 col-xs-12 col-sm-12 col-lg-6 mt-3">
              <TextField className={classes.textField} label="Salario bruto" type="text" name="sueldoBruto" onChange={onChangeSalarial} value={formSalario.sueldoBruto} variant="outlined" required
              InputProps={{
                startAdornment: <InputAdornment position="start"> $</InputAdornment>,
              }}/>
            </div>
            <div className="col-md-4 col-xs-12 col-sm-12 col-lg-6 mt-3">
              <Form.Group controlId="empresa-contrato">
                <FormControl className={classes.textField} variant="outlined">
                  <InputLabel>Empresa Contratante </InputLabel>
                  <Select className={classes.textField} native label="Empresa Contratante" required as="select" name="tipoContrato" onChange={ChangeAliasContrato} >
                      <option />
                      <option>OMA</option>
                      <option>ATLACONTIS</option>
                      <option>BRAMATADO</option>
                  </Select>
                </FormControl>
              </Form.Group>
            </div>
            <div className="col-md-6 col-xs-12 col-sm-12 col-lg-6 mt-3">
              <Autocomplete
                id="TituloContrato"
                options={tipoContratos}
                value={selectTipoContrato}
                getOptionLabel={(option) => option.st_Descripcion}
                onChange={(option, value) => ChangeSelectContrato(value)}
                getOptionSelected={(option, value) => true }
                renderInput={(params) => <TextField className={classes.textField} {...params} label="Tipo Contrato" variant="outlined" />}
              />
            </div>
            <div className="col-md-6 col-xs-12 col-sm-12 col-lg-6 mt-3">
              <Autocomplete
                id="DetalleVontratos"
                options={detalleContratos}
                value={selectDetalleContratos}
                getOptionLabel={(option) => option.st_NombreArchivo}
                onChange={(option, value) => ChangeDetalleContratos(value)}
                getOptionSelected={(option, value) => true }
                renderInput={(params) => <TextField className={classes.textField} {...params} label="Contrato" variant="outlined" />}
              />
            </div>
        </form>
    </div>
  )
}

export default TabSalario