import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Login from '../pages/Login';
import Request from '../async/request';

const LoginContainer = () =>{
    const [form, setForm] = useState({ correo: '', pass: '' });
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(true);
    const history = useHistory();

    useEffect(
        () => {
            let auth = JSON.parse(sessionStorage.getItem('auth'));
            if (auth !== null) {
                const fetchData = async () => {
                    let request = new Request("POST", null, 1, "service/valida");
                    let data = await request.exec();
                    if (data.isSuccess)
                        history.push("/index");
                    else
                        setLoad( false );
                }
                fetchData();
            }
            else{
                setLoad( false );
            }
        }, [history]
    )

    const onChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        let request = new Request("POST", form, 1, "service/autentica");
        let response = await request.exec();
        if ( response.isSuccess ) {
            sessionStorage.setItem('auth', JSON.stringify( response.data ));
            console.log(response.data.tipo);
            if(response.data.tipo === "S"){
                history.push("/cursos");
            }else{
                history.push("/index");
            }
            
        }
        else {
            setMsg(response.message);
            setLoading(false);
        }
    }

    const hiddenPassword = () =>{
        var element = document.getElementById("pass");
        if ( element.getAttribute("type") === "text" )
            element.setAttribute("type", "password");
        else
            element.setAttribute("type", "text");
        
    }

    return(
        <Login hiddenPassword={hiddenPassword} load={load} handleSubmit={handleSubmit} onChange={onChange} msg={msg} loading={loading} />
    )
};

export default LoginContainer;