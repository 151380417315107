import React, { useEffect, useState } from 'react';
import Request from '../../async/request';

const SectionEmergency = ({ idPersonal }) => {
    const [contactos, setContactos] = useState([]);

    useEffect(
        () => {
            async function get() {
                let request = new Request("GET", null, true, "control/empleado_emergencia/find/" + idPersonal);
                let response = await request.exec();
                console.log(idPersonal)
                if (response.isSuccess)
                    if (response.data !== null)
                        setContactos(response.data);
            }
            get();
        }, [idPersonal]
    )

    return (
        <div className="col-md-8 mt-4">
            <h3 className="text-center">Contactos de emergecia</h3>
            <div className="content-center mb-4">
                <div className="line-title" />
            </div>
            <div className="row">

                {
                    contactos.map(
                        (item, i) => (
                            <div key={i} className="col-md-6 text-center">
                                <p>
                                    <span>Nombre: </span>
                                    <span>{item.nombreReferencia}</span>
                                </p>
                                <p>
                                    <span>Teléfono: </span>
                                    <span>{item.telefono ? item.telefono : "No proporcionado"}</span>
                                </p>
                                <p>
                                    <span>Celular: </span>
                                    <span>{item.celular}</span>
                                </p>
                                <p>
                                    <span>Parentesco: </span>
                                    <span>{item.parentesco}</span>
                                </p>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
};

export default SectionEmergency;