import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
    theme => ({
        textField:{
            width: '100%', 
            '& label.Mui-focused': {
                color: '#001956',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#001956',
            },
            '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#9ba4b4',
            },
            '& .MuiFormLabel-root':{
                color: '#fff'
            },
            '&:hover fieldset': {
                borderColor: '#0014CA',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#001956',
            },
            },
        },textFieldError:{
            width: '100%', 
            '& label.MuiFormLabel-root': {
                color: '#FF0000',
            },
            '& label.MuiInputBase-root': {
                color: '#FF0000',
            },
        },
        selectField:{
            width: '100%',
            '& label.Mui-focused': {
                color: '#001956',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#001956',
            },
            '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#9ba4b4',
            },
            '&:hover fieldset': {
                borderColor: '#0014CA',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#001956',
            },
            },
        }
    })
)

export default useStyles;