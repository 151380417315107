import React from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, FormControl, FormGroup, TextField, FormControlLabel,  List, ListItem, ListItemText, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const Contratos = ({classes, form, ChangeForm, proyectos, selectProyecto, ChangeProyecto, personas, selectPersona, ChangePersona, seleccionPersonas, todos, ChangeBox, DeletePersona, onSubmit}) => {
  return (
    <div className='container-principal'>
        <div className="info-container">
            <div className="row">
                <div className='col-md-4 mt-3'>
                    <h4>Proyectos Disponibles</h4>
                    <hr></hr>
                </div>
                <div className='col-md-6 mt-3'>
                    <h4>Empleados</h4>
                    <hr></hr>
                </div>
                <div className='col-md-4 mt-3'>
                    <FormControl className={classes.textField} variant="outlined">
                        <Autocomplete
                            id="proyectos"
                            options={proyectos}
                            value={selectProyecto}
                            getOptionLabel={(option) => option.st_TituloProyecto}
                            onChange={(option, value) => ChangeProyecto(value)}
                            getOptionSelected={(option, value) => true }
                            renderInput={(params) => <TextField className={classes.textField} {...params} label="Escoge un proyecto" variant="outlined" />}
                        />
                    </FormControl>
                </div>         
                {
                    !todos ? (
                    <div className='col-md-4 mt-3'>
                        <FormControl className={classes.textField} variant="outlined">
                            <Autocomplete
                                id="personas"
                                options={personas}
                                value={selectPersona}
                                getOptionLabel={(option) => option.nombre + " " + option.apellidoPaterno + " " + option.apellidoMaterno}
                                onChange={(option, value) => ChangePersona(value)}
                                getOptionSelected={(option, value) => true }
                                renderInput={(params) => <TextField className={classes.textField} {...params} label="Colaboradores" variant="outlined" />}
                            />
                        </FormControl> 
                    </div> ) : void (0) 
                }
                
                <div className='col-md-4 mt-4'>
                    <FormGroup>
                        <FormControlLabel
                        label={`Seleccionar todos los colaboradores de ${selectProyecto !== null ? selectProyecto.st_TituloProyecto : "" }`}
                        control={
                            <Checkbox
                            checked={todos}
                            onChange={ChangeBox}
                            color="default"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                        }
                        />
                    </FormGroup>
                </div>
                <div className='col-md-6 mt-3'>
                    <TextField className={classes.textField} id="fechaInicio" name="fechaInicio" InputLabelProps={{ shrink: true}} value={form.fechaInicio} onChange={ChangeForm} variant="outlined" label="Fecha de incio para contrato" type="date" inputProps={{ autoComplete: "off"}}/>
                </div>
                <div className='col-md-6 mt-3'>
                    <TextField className={classes.textField} id="fechaFin" name="fechaFin" InputLabelProps={{ shrink: true}} value={form.fechaFin} onChange={ChangeForm} variant="outlined" label="Fecha para finalizar el contrato" type="date" inputProps={{ autoComplete: "off"}}/>
                </div>
            </div>
            <div className="row mt-4">
                <h5>Personas seleccionadas:</h5>
              {   
                seleccionPersonas.length !== 0 ?
                <div className='col-md-4 col-sm-12 col-xs-12 col-lg-4'>
                  <List disablePadding={false} dense={true}>
                    {
                      seleccionPersonas.map(
                        (persona) => (
                        <ListItem key={persona.idPersonal}>
                          <ListItemText primary={`${persona.nombre} ${persona.apellidoPaterno} ${persona.apellidoMaterno} `}/>
                            <IconButton aria-label="delete" onClick={() => DeletePersona(persona.idPersonal)}>
                              <DeleteIcon />
                            </IconButton>
                        </ListItem>
                      ))
                    }
                  </List>
                </div> : void(0)
              }                  
          </div>
          <div className='col-md-12 mt-4'>
              <button onClick={onSubmit} type="button" className='btn-rhea-alternative'> Generar Contratos</button>
            </div>
        </div>
        <NavRHEA />
    </div>
  )
}

export default Contratos