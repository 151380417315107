import { Avatar } from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {  Link, useHistory } from 'react-router-dom';
import Request from '../../async/request';
import Logo from '../../images/logo_atlantisba.png';

const NavRHEA = ({menu = true}) =>{
    const history = useHistory();
    const [show, setShow] = useState("sidebar-hidden");
    const [info, setInfo] = useState({name:'',id:'', tipo:''});
    const [foto, setFoto] = useState(null);
    const ref = useRef();

    useEffect(
        () =>{
            async function fetchAsync(){
                let auth = JSON.parse( sessionStorage.getItem("auth") );
                if( auth != null ){
                    setInfo({ name:auth.nombre, id: auth.idPersonal, tipo: auth.tipo });
                    let request = new Request("POST", null, 1, "service/descarga/foto/" + auth.idPersonal );
                    let response = await request.blob();
                    let img = URL.createObjectURL( response );
                    setFoto( img );
                }
            }
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow("sidebar-hidden")
                }
            }
            fetchAsync();
            document.addEventListener("mousedown", handleClickOutside);
        },[]
    );
    

    const onClick = () => setShow( "sidebar-show" );

    const onHide = () => setShow( "sidebar-hidden" );

    const logout = () =>{
        sessionStorage.clear();
        history.push("/");
    }

    return(
        <Fragment>
            <nav className="navbar-rhea">
                <div>
                    <img alt="Logo" src={Logo} className="nav-image" />
                </div>
                <div className="d-flex align-items-center">
                    <div className="mr-3">
                        { info.id ?  <Avatar alt={info.name} src={foto} /> : void(0) }
                    </div>
                    <div>
                        <p className="text-user">{info.name}</p>
                        { info.id ? <p className="text-user">ID: {info.id}</p> : void(0)}
                    </div>
                    {menu ?
                        <i onClick={onClick} className="icon-menu fas fa-bars"></i>
                        :
                        void(0)
                    }
                </div>
            </nav>
            {
                menu ?
                <div ref={ref} className={show}>
                    <i onClick={onHide} className="fas fa-times"></i>
                    <div className="d-flex flex-column">
                        <div>
                            <p className="text-user-mobile">{info.name}</p>
                            <p className="text-user-mobile">{info.id}</p>
                        </div>
                        { info.tipo !== "S" ? 
                            (
                                <>
                                <Link to="/index" className="link-menu">Inicio</Link>
                                <Link to="/perfil" className="link-menu">Mi perfil</Link>
                                {info.tipo === "A" ? (
                                    <>
                                    <Link to="/personal" className="link-menu">Administración de personal</Link>
                                    <Link to="/organigrama" className="link-menu">Organigrama</Link>
                                    <Link to="/atraccion" className="link-menu">Atracción de talento</Link>
                                    <Link to="/contratos" className="link-menu">Generar Contratos</Link>
                                    </>
                                ) : void(0)}
                                <Link to="/solicitudes" className="link-menu">Solicitudes</Link>
                                </>
                            ) : (
                                <Link to="/cursos" className="link-menu">Creación de contancias</Link>
                            )
                        }
                        <p onClick={logout} className="link-menu">Cerrar sesión</p>
                    </div>
                </div>
                :
                void(0)
            }
        </Fragment>
    )
};

export default NavRHEA;