import React from 'react';
import { InputLabel, TextField, Select, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TabLaboral = ({
    alias,
    puestos,
    onChangeLaboral,
    changeAlias,
    changeArea,
    areas,
    form,
    personas,
    classes
}) => {
    return (
        <div className="mt-4 r-opacity col">
            <form className="row" Autocomplete="off">
                <div className="col-md-6 mt-3">

                    <TextField className={classes.textField} variant="outlined" native label="Empresa" value={form.alias} required as="text" name="alias" onChange={changeAlias} inputProps={{ list: "empresas", name: "alias", id: "alias",  Autocomplete: "off" }} />
                    <datalist id="empresas">
                        {alias.map(
                            (alias, i) => <option value={alias.item} key={i}>{alias.item}</option>
                        )}
                    </datalist>

                </div>
                <div className="col-md-6 mt-3">

                    <TextField className={classes.textField} variant="outlined" native label="Área" value={form.area} onChange={changeArea} required as="select" name="alias" inputProps={{ name: "estadoCivil", id: "estadoCivil", list: 'areas', Autocomplete: "off"  }} />
                    <datalist id="areas">
                        {areas.map(
                            (areas, i) => <option value={areas.item} key={i}>{areas.item}</option>
                        )}
                    </datalist>
                </div>
                <div className="col-md-6 mt-3">
                        <TextField className={classes.textField} variant="outlined" native label="Puesto" value={form.puesto} required as="select" onChange={onChangeLaboral} name="puesto" inputProps={{ list:"puestos", name: "puesto", id: "puesto", Autocomplete: "off" }} />
                        <datalist id="puestos">

                            {puestos.map(
                                (areas, i) => <option value={areas.item} key={i}>{areas.item}</option>
                            )}
                        </datalist>
                </div>
                <div className="col-md-6 mt-3">
                    <Autocomplete
                        id="combo-box-demo"
                        options={personas}
                        getOptionLabel={(option) => option.nombre + " " + option.apellidoPaterno + " " + option.apellidoMaterno}
                        getOptionSelected={(option, value) => true}
                        onChange={(option, val) => val !== null ? onChangeLaboral({ target: { value: val.idPersonal, name: 'idPersonalJefe' } }) : void (0)}
                        renderInput={(params) => <TextField className={classes.textField} {...params} label="Jefe" variant="outlined" />}
                    />
                </div>
                <div className="col-md-4 mt-3">
                    <TextField className={classes.textField} variant="outlined" label="Fecha de ingreso" required type="date" name="fechaIngreso" value={form.fechaIngreso} onChange={onChangeLaboral} />
                </div>
                <div className="col-md-4 mt-3">
                    <FormControl className={classes.textField} variant="outlined">
                        <InputLabel>Región</InputLabel>
                        <Select native label="Región" value={form.ubicacion} required as="select" onChange={onChangeLaboral} name="ubicacion">
                            <option />
                            <option>CDMX</option>
                            <option>Regional</option>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4 mt-3">
                    <TextField className={classes.textField} variant="outlined" label="Lugar asignado" required type="text" name="lugarAsignado" value={form.lugarAsignado} onChange={onChangeLaboral} />
                </div>
            </form>
        </div>
    )
};

export default TabLaboral;