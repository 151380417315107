import { TextField, Fab } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form';
import useStyles from '../../theme/ThemeInput';

const FormularioContacto = ({btnText="Enviar", form, onChange, handleSubmit}) => {
    const classes = useStyles();

    return(
        <Fragment>
            <div className="container-form-rhea">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-telefono">
                                <TextField className={classes.textField} variant="outlined" label="Teléfono fijo" type="tel" name="telefono" value={form.telefono} inputProps={{ maxLength: 10,pattern:"[0-9]{10}",autoComplete: "off", title:"Ingresa sólo los 10 dígitos de tu número local"}} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-celular">
                                <TextField className={classes.textField} variant="outlined" label="Celular"required type="tel" name="celular" inputProps={{ maxLength: 10,pattern:"[0-9]{10}",autoComplete: "off", title:"Ingresa sólo los 10 dígitos de tu número celular"}} value={form.celular} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-correo">
                                <TextField className={classes.textField} variant="outlined" label="Correo"required type="email" name="correo" inputProps={{ maxLength: 60,autoComplete: "off"}} value={form.correo} onChange={onChange}/>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="mt-4 text-center">
                        <Fab type="submit" className="button-send" variant="extended">
                            <NavigationIcon />
                            {btnText}
                        </Fab>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default FormularioContacto;