import React, { useEffect, useRef, useState } from "react";
import Organigrama from '../pages/Organigrama';
import Request from "../async/request";
import { useHistory } from "react-router";

const OrganigramaContainer = () =>{
    const [org, setOrg] = useState({});
    const orgChart = useRef();
    const history = useHistory();

    useEffect(
        () =>{
            async function fetchFunction(){
                let request = new Request("POST", null, 1, "service/valida");
                let response = await request.exec();
                if (response.isSuccess) {
                    let request = new Request("GET", null, 1, "mapper/organigrama");
                    let response = await request.exec();
                    if( response.isSuccess ){
                        let array = [];
                        array = response.data;
                        let id = array[0].idPersonal; 
                        let organigrama = {
                            id: array[0].idPersonal,
                            name: array[0].nombre,
                            title: array[0].puesto,
                            parent: array[0].idPersonalJefe,
                            children:[]
                        }
                        getFirstNode( organigrama, array, id );
                        setOrg( organigrama );
                    }
                }
                else{
                    history.push("/")
                }
            }
            function getFirstNode(organigrama, array, id){
                let aux = [];
                for( let  j = 0; j < array.length; j++ ){
                    if( id === array[j].idPersonalJefe ){
                        let item = {
                            id: array[j].idPersonal,
                            name: array[j].nombre,
                            title: array[j].puesto,
                            parent: array[j].idPersonalJefe,
                            children:[]
                        }
                        aux.push( item );
                    }
                }
                organigrama.children = aux;
                return organigrama;
            }
            fetchFunction();
        },[history]
    );


    const clickNode = async (item) =>{
        let request = new Request("GET", null, 1, "mapper/organigrama");
        let response = await request.exec();
        if( response.isSuccess ){
            let array = response.data;
            if( item.id === org.id ){
                for (let j = 0; j < array.length; j++) {
                    if( item.parent === array[j].idPersonal ){
                        item = {
                            id: array[j].idPersonal,
                            name: array[j].nombre,
                            title: array[j].puesto,
                            parent: array[j].idPersonalJefe,
                            children: []
                        }
                        break;
                    }
                }
            }
            let id = item.id;
            let organigrama = {
                id: item.id,
                name: item.name,
                title: item.title,
                parent: item.parent,
                children:[]
            }
            if( item.parent === "" )
                getFirstNode( organigrama, array, id  );
            else
                getNode( organigrama, array, id );
            setOrg( organigrama );
        }
    }

    function getNode(organigrama, array, id) {
        let aux = [];
        for (let j = 0; j < array.length; j++) {
            if (id === array[j].idPersonalJefe) {
                let item = {
                    id: array[j].idPersonal,
                    name: array[j].nombre,
                    title: array[j].puesto,
                    parent: array[j].idPersonalJefe,
                    children: []
                }
                aux.push(item);
                getNode(item, array, array[j].idPersonal);
            }
        }
        organigrama.children = aux;
        return organigrama;
    }

    function getFirstNode(organigrama, array, id){
        let aux = [];
        for( let  j = 0; j < array.length; j++ ){
            if( id === array[j].idPersonalJefe ){
                let item = {
                    id: array[j].idPersonal,
                    name: array[j].nombre,
                    title: array[j].puesto,
                    parent: array[j].idPersonalJefe,
                    children:[]
                }
                aux.push( item );
                // getNode( item, array, array[j].idPersonal );
            }
        }
        organigrama.children = aux;
        return organigrama;
    }

    return(
        <Organigrama clickNode={clickNode} org={org} orgChart={orgChart} />
    )
};

export default OrganigramaContainer;