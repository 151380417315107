import React, { useEffect, useState } from 'react';
import Request from '../../async/request';
import AvatarUser from '../Others/AvatarUser';

const SectionFoto = ({user, personal = false, baja }) =>{
    const [foto, setFoto] = useState(null);

    useEffect(
        () =>{
            async function get(){
                let request = new Request("POST", null, 1, "service/descarga/foto/" + user.idPersonal );
                let response = await request.blob();
                let img = URL.createObjectURL( response );
                setFoto( img );
            }
            get();
        },[user]
    );

    return(
        <div className="text-center col-md-4">
            <div className="row justify-content-center">
                <AvatarUser className="avatar-perfil" path={foto} name={user.nombre} />
            </div>
            {
                personal ? 
                    <div>
                        <div className="d-flex justify-content-center mt-4">
                            <input onChange={baja} checked={user.activo} className="switch" type="checkbox" id="switch" />
                            <label className="switch-label" htmlFor="switch">Toggle</label>
                        </div>
                        <div>
                            <h5 className="mt-3">{user.activo ? "Activo":"Inactivo"}</h5>
                        </div>
                    </div>
                :
                void(0)
            }
        </div>
    )
};

export default SectionFoto;