import React, { Fragment, useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import { Close, Notifications } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Request from '../../async/request';
import DateFormatFix from '../../utils/DateFormatFix';

const Notification = () =>{
    const [cn, setCN] = useState("notification-container-hidden");
    const [notificaciones, setNotificaciones] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(
        () =>{
            const timer = setTimeout(() => {
                async function fetchData(){
                    let auth = JSON.parse( sessionStorage.getItem("auth") );
                    let request = new Request("GET", null, 1, "control/notificacion/id/" + auth.idPersonal );
                    let response = await request.exec();
                    setNotificaciones( response.data );
                    let conteo = 0;
                    for( let i = 0; i < response.data.length; i++ ){
                        if( !response.data[i].visto )
                            conteo ++;
                    }
                    setCount( conteo );
                }
                fetchData();
            },10000);

            return (
                () => clearTimeout(timer)
            )
        },[notificaciones]
    )
    
    const changeState = async () =>{
        if( cn === "notification-container-hidden" ){
            setCN("notification-container");
            let auth = JSON.parse( sessionStorage.getItem("auth") );
            let request = new Request("POST", null, 1, "control/notificacion/ver/" + auth.idPersonal );
            let response = await request.exec();
            request = new Request("GET", null, 1, "control/notificacion/id/" + auth.idPersonal );
            response = await request.exec();
            setNotificaciones( response.data );
            let conteo = 0;
            for( let i = 0; i < response.data.length; i++ ){
                if( !response.data[i].visto )
                    conteo ++;
            }
            setCount( conteo );
        }
        else{
            setCN("notification-container-hidden");
            let auth = JSON.parse( sessionStorage.getItem("auth") );
            let request = new Request("GET", null, 1, "control/notificacion/id/" + auth.idPersonal );
            let response = await request.exec();
            setNotificaciones( response.data );
            let conteo = 0;
            for( let i = 0; i < response.data.length; i++ ){
                if( !response.data[i].visto )
                    conteo ++;
            }
            setCount( conteo );
        }
    }

    return(
        <Fragment>
            <div className={"fixed-bottom " + cn } >
                <div className="pt-4 bg-top">
                    <h3 className="text-center text-white">Notificaciones</h3>
                </div>
                <div className="items-notification">
                    {/* <div className="d-flex flex-row">
                        <div className="w-50">
                            <button className="btn-notification">Borrar notificaciones</button>
                        </div>
                        <div className="w-50">
                            <button className="btn-notification">Ver todas</button>
                        </div>
                    </div> */}
                    <div className="card-notification">
                        {
                            notificaciones.length > 0 ?
                                notificaciones.map(
                                    (item, i) =>
                                        <Link key={i} to={"/" + item.ruta}>
                                            <div className="">
                                                <p className="card-notification-title">{item.titulo}</p>
                                                <p className="card-notification-body">{item.mensaje}</p>
                                                <p className="card-notification-date">{new DateFormatFix( new Date( item.created ) ).getFormat()}</p>
                                            </div>
                                        </Link>
                                )
                                :
                                <p className="card-notification-body text-center">No hay notificaciones por ahora</p>
                        }
                    </div>
                </div>
            </div>
            <div className="fixed-bottom">
                <div onClick={changeState} className="notification-btn">
                    {cn === "notification-container-hidden" ?
                        <Badge className="r-animation" color="primary" badgeContent={ count }>                        
                            <Notifications />
                        </Badge>
                        :
                        <Close className="r-animation" />
                    }
                </div>
            </div>
        </Fragment>
    )
};

export default Notification;