import React, { useState, useEffect } from 'react'
import Cursos from '../pages/Cursos';
import useStyles from '../theme/ThemeInput';
import Request from '../async/request';
import DateFormatFix from '../utils/DateFormatFix';

const CursosContainer = () => {
    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [todos, setTodos] = useState(false);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [alias, setAlias] = useState("");
    const [personas, setPersonas] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectArea, SetSelectArea] = useState(null);
    const [colaboradores, setColaboradores] = useState([]);
    const [selectPersona, setSelectPersona] = useState(null);
    const [seleccionPersonas, setSeleccionPersonas] = useState([]);
    const [formato, setFormato] = useState("");
    const [formDC3, setFormDC3] = useState({representanteTrabajadores: ''});
    const [formDC4, setFormDC4] = useState({vigenciaInicio: '', vigenciaFin: '', correoContacto: '', telefonoContacto: '', nombreNorma: '', lugarExpedicion: '', fechaExpedicion: new DateFormatFix(new Date()).getDate(), fechaEmisionCertificado: ''});
    const [formCurso, setFormCurso] = useState({nombreCurso: '', horasCurso: '', areaTematica: '', modalidadCurso: '', nombreAgente: '', numeroRegistroAgente: '', objetivoCurso:'', fechaInicioEjec: '', fechaFinEjec: '', tipoAgente: ''});

    //OBTENEMOS A TODOS LOS COLABORADORES
    useEffect(
        () => {
            async function fetchData() {
                let request = new Request("GET", null, 1, "control/empleado_general");
                let response = await request.exec();
                if (response.isSuccess) {
                    setPersonas(response.data);
                }
            }
            async function GetAreas(){
                let request = new Request("GET", null, 1, "service/areasCursos");
                let response = await request.exec();
                if (response.isSuccess) {
                    setAreas(response.data);
                } 
            }
            fetchData();
            GetAreas();
        }, []
    )

    const changeAreaCurso = (value) => {
        SetSelectArea(value);
        areas.forEach((alias, indice) => {
            if(alias.clave_curso === value.clave_curso)
                setFormCurso({...formCurso, areaTematica : value.clave_curso});
        });
    }

    const ChangeForm = (e) => {
        if(e.target.id === "vigenciaFin"){
            let fechaOk = VerificaFechaDC4(e.target.value);
            if(fechaOk){
                setFormDC4({ ...formDC4, [e.target.id]: e.target.value });
            }else{
                fillError('Campo Erróneo', 'En el Formato DC4, la fecha vigente de inicio tiene que ser menor a la fecha fin de vigencia');
                return;
            }
        }else{
            setFormDC4({ ...formDC4, [e.target.id]: e.target.value });
        }
    }

    const ChangeFormDC3 = (e) => {
        setFormDC3({ ...formDC3, [e.target.id]: e.target.value });
    }

    const ChangeFormCurso = (e) => {
        if(e.target.id === "fechaFinEjec"){
            let fechaOK = VerificaFechaCurso(e.target.value);
            if(fechaOK){
                setFormCurso({ ...formCurso, [e.target.id]: e.target.value });
            }else{
                fillError('Campo Erróneo', 'La fecha de inicio de ejecución del curso debe ser menor a la fecha de ejecución fin');
                return;
            }
        }else{
            setFormCurso({ ...formCurso, [e.target.id]: e.target.value });
        }
       
    }

    const DeletePersona = (idPersonal) => {
        setSeleccionPersonas( seleccionPersonas.filter( persona => persona.idPersonal !== idPersonal));
    }

    const ChangeEmpresa= (e) => {
        if(e.target.value === "KAMBERG"){
            setFormDC4({ ...formDC4, 'telefonoContacto': '5513289247' });
        }
        setAlias(e.target.value);
        getMostrarColaboradores(e.target.value);
    }

    const ChangePersona = (arreglo) => {
        if(arreglo !== null){
            setSelectPersona(arreglo);
            setSeleccionPersonas([...seleccionPersonas, arreglo]);
        }
    }

    const ChangeFormato = (e) => {
        setFormato(e.target.value);
        if(e.target.value === "DC4"){
            setFormDC3({representanteTrabajadores: ''});
        }else if(e.target.value === "DC3"){
            setFormDC4({vigenciaInicio: '', vigenciaFin: '', correoContacto: '', telefonoContacto: '', nombreNorma: '', lugarExpedicion: '', fechaExpedicion: new DateFormatFix(new Date()).getDate(), fechaEmisionCertificado: ''});
        }
        setSeleccionPersonas([]);
        setTodos(false);
    }

    const GetPersonasEmpresa = async() => {
        let request = new Request("GET", null, 1, "control/empleado_laboral/findPorAlias/" + alias);
        let response = await request.exec();
        let encontrados = [];
        if (response.isSuccess) {
            let arreglo = response.data;
            arreglo.forEach((element, index) => {
                let arr_find = personas.filter( persona => persona.idPersonal === arreglo[index]["idPersonal"] );
                encontrados.push(arr_find[0]);
            });
            setSeleccionPersonas(encontrados);
        } else { setSeleccionPersonas([]); }
    }

    const getMostrarColaboradores = async (alias) => {
        let request = new Request("GET", null, 1, "control/empleado_laboral/findPorAlias/" + alias);
        let response = await request.exec();
        let encontrados = [];
        if (response.isSuccess) {
            let arreglo = response.data;
            arreglo.forEach((element, index) => {
                let arr_find = personas.filter( persona => persona.idPersonal === arreglo[index]["idPersonal"] );
                encontrados.push(arr_find[0]);
            });
            //ordenamos por orden alfabético referente al nombre del colaborador
            encontrados.sort(function(a,b){
                return a["nombre"].localeCompare(b["nombre"]);
            });
            setColaboradores(encontrados);
        } else { setColaboradores([])}
    }

    const ChangeBox = () => {
        if(todos !== true){
            if(alias === '')
                alert("Para seleccionar a todos los colaboradores ingresa una empresa");
            else{
                setTodos(!todos);
                setSelectPersona(null);
                setSeleccionPersonas([]);
                GetPersonasEmpresa();
            }
        }else{
            setTodos(!todos);
            setSelectPersona(null);
            setSeleccionPersonas([]);
        }
    }

    const ValidateForm = () => {
        var isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(alias === ""){
            fillError('Campo Erróneo', 'Selecciona una empresa');
            return false;
        }else if(formato === ""){
            fillError('Campo Erróneo', 'Selecciona un formato');
            return false;
        }else if (formCurso.nombreCurso === ''){
            fillError('Campo Erróneo', 'Ingresa el nombre del curso');
            return false;
        }else if (formCurso.horasCurso === ''){
            fillError('Campo Erróneo', 'Ingresa las horas del curso');
            return false;
        }else if (formCurso.areaTematica === ''){
            fillError('Campo Erróneo', 'Ingresa la temática del curso');
            return false;
        }else if (formCurso.modalidadCurso === ''){
            fillError('Campo Erróneo', 'Ingresa la modalidad del curso');
            return false;
        }else if (formCurso.nombreAgente === ''){
            fillError('Campo Erróneo', 'Ingresa el nombre del agente instructor');
            return false;
        }else if(formCurso.tipoAgente === ""){
            fillError('Campo Erróneo', 'Ingresa el tipo de Agente');
            return false;
        }else if( formCurso.tipoAgente === "2" && formCurso.numeroRegistroAgente === ''){
            fillError('Campo Erróneo', 'Ingresa el número de registro del agente ante la STP');
            return false;
        }else if(formCurso.objetivoCurso === ""){
            fillError('Campo Erróneo', 'Ingresa el objetivo del curso');
            return false;
        }else if(formCurso.fechaInicioEjec === ""){
            fillError('Campo Erróneo', 'Ingresa la fecha de inicio del curso');
            return false;
        }else if(formCurso.fechaFinEjec === ""){
            fillError('Campo Erróneo', 'Ingresa la fecha de termino del curso');
            return false;
        }else if(seleccionPersonas.length === 0 || seleccionPersonas === ""){
            fillError('Campo Erróneo', 'Selecciona los colaboradores correctos');
            return false;
        }else if( formCurso.fechaFinEjec < formCurso.fechaInicioEjec ){
            fillError('Campo Erróneo', 'La fecha de inicio de ejecución del curso debe ser menor a la fecha de ejecución fin');
            return false;
        }else if( formato === "DC3" && formDC3.representanteTrabajadores === ""){
            fillError('Campo Erróneo', 'Ingresa al representante de los colaboradores');
            return false;
        }else if(formato === "DC4" && formDC4.vigenciaInicio === ""){
            fillError('Campo Erróneo', 'Revisa la fecha de la vigencia de inicio');
            return false;
        }else if(formato === "DC4" && formDC4.vigenciaFin === ""){
            fillError('Campo Erróneo', 'Revisa la fecha de la Vigencia final');
            return false;
        }else if(formato === "DC4" && formDC4.correoContacto === ""){
            fillError('Campo Erróneo', 'Revisa el correo de contacto');
            return false;
        }else if(formato === "DC4" && formDC4.telefonoContacto.length !== 10) {
            fillError('Campo Erróneo', 'Revisa el teléfono de contacto (tienen que ser 10 dígitos)');
            return false;
        }else if(formato === "DC4" && formDC4.lugarExpedicion === ""){
            fillError('Campo Erróneo', 'Revisa el lugar de expedición');
            return false;
        }else if(formato === "DC4" && formDC4.fechaExpedicion === ""){
            fillError('Campo Erróneo', 'Revisa la fecha de expedición del documento DC4');
            return false;
        }else if(formato === "DC4" && formDC4.vigenciaFin < formDC4.vigenciaInicio){
            fillError('Campo Erróneo', 'En el formato DC4: la fecha inicio de Vigencia debe de ser menor a la fecha fin de vigencia');
            return false;
        }else if( formato ==="DC4" && !isEmail.test(formDC4.correoContacto)){
            fillError('Campo Erróneo', 'en el formato DC4: revisa el correo de contacto de la persona correspondiente');
            return false;
        }else{
            return true;
        }

        /*
        else if(formato === "DC4" && formDC4.nombreNorma === ""){
            fillError('Campo Erróneo', 'Revisa el nombre de la norma'); 
            return false;
        }
        */
    }

    const VerificaFechaCurso = (e) => {
        if(e < formCurso.fechaInicioEjec){
            return false;   
        }else{
            return true;
        }
    }

    const VerificaFechaDC4 = (e) => {
        if(e < formDC4.vigenciaInicio){
            return false;   
        }else{
            return true;
        }
    }

    const VerificaCorreo = (e) => {
        console.log("ENTRO CORREO");
        let isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!isEmail.test(e.target.value)){
            setIsError(true);
            document.getElementById("text-invalidEmail").innerHTML =  'Correo Inválido';
            fillError('Campo Erróneo', 'en el formato DC4: revisa el correo de contacto de la persona correspondiente');
            return;
        }else{
            setIsError(false);
            document.getElementById("text-invalidEmail").innerHTML =  '';
            return;
        }
    }

    const fillError = (title, body) => {
        setTitle(title);
        setBody(body);
        setShow(true);
        return;
    }

    const CerrarModal = () => {
        setTitle("");
        setBody("");
        setShow(false);
    }
    
    const FormSubmit = async() => {
        let body = {
            empresa: alias,
            colaboradores: seleccionPersonas,
            formato: formato,
            infoDC4 : formDC4,
            infoDC3 : formDC3,
            curso: formCurso
        }
        console.log(body);
        let validar = ValidateForm();
        if(validar){
            let request = new Request("POST", body, 0, "service/constancias");
            let response = await request.blob();
            var file = window.URL.createObjectURL( response );
            window.open( file, "_blank"  );
        }
        
    }

    return (
        <Cursos show={show} title={title} body={body} CerrarModal={CerrarModal} todos={todos} alias={alias} ChangeEmpresa={ChangeEmpresa} classes={classes} personas={personas} selectPersona={selectPersona} seleccionPersonas={seleccionPersonas} ChangeBox={ChangeBox} ChangePersona={ChangePersona} DeletePersona={DeletePersona} formato={formato} ChangeFormato={ChangeFormato} ChangeForm={ChangeForm} formDC4={formDC4} formCurso={formCurso} ChangeFormCurso={ChangeFormCurso} FormSubmit={FormSubmit} ChangeFormDC3={ChangeFormDC3} formDC3={formDC3} colaboradores={colaboradores} VerificaCorreo={VerificaCorreo} isError={isError} areas={areas} changeAreaCurso={changeAreaCurso} selectArea={selectArea}/>
    );
}
export default CursosContainer;