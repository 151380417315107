import React, { Fragment } from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TabLaboral from '../components/Tabs/TabLaboral';
import CardNuevo from '../components/Cards/CardNuevo';
import ModalError from '../components/Modal/ModalError';
import TabSalario from '../components/Tabs/TabSalario';

const Atraccion = ({
    nuevos,
    tabs,
    classes,
    onChange,
    changeTab,
    alias,
    puestos,
    onChangeLaboral,
    changeAlias,
    changeArea,
    areas,
    form,
    personas,
    seleccion,
    onSubmit,
    modal,
    onHide,
    formSalario,
    onChangeSalarial,
    ChangeAliasContrato,
    tipoContratos,
    selectTipoContrato,
    ChangeSelectContrato,
    detalleContratos,
    ChangeDetalleContratos,
    selectDetalleContratos
}) => (
    <Fragment>
        <div className="container-principal">
            <ModalError mostrar={modal.show} mensaje={modal.body} ocultarModal={onHide} title={modal.title} />
            <div className="info-container">
                <div className="row">
                    <div className="col-md-4 mt-4">
                        <h3 className="text-center">Nuevos talentos</h3>
                        <div>
                            <div className="mt-4 pr-3 pl-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={nuevos}
                                    value={seleccion}
                                    getOptionLabel={(option) => option.nombre + " " + option.apellidoPaterno + " " + option.apellidoMaterno}
                                    getOptionSelected={(option, value) => true }
                                    onChange={(option, value) => onChange(value)}
                                    renderInput={(params) => <TextField className={classes.textField} {...params} label="Buscar" variant="outlined" />}
                                />
                            </div>
                            {
                                seleccion.foto ?
                                    <CardNuevo seleccion={seleccion} />
                                    :
                                    <div className="mt-5">
                                        <h5 className="text-center">Selecciona un nuevo talento</h5>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-8 mt-4">
                        <div className="row">
                            <div id="1" onClick={changeTab} className={"tabs col-6 " + (tabs[0] ? "active" : "inactive")}>
                                <h5>Información laboral</h5>
                            </div>
                            <div id="2" onClick={changeTab} className={"tabs col-6 " + (tabs[1] ? "active" : "inactive")}>
                                <h5>Información salarial</h5>
                            </div>
                            {/*
                                <div id="3" onClick={changeTab} className={"tabs col-4 " + (tabs[2] ? "active" : "inactive")}>
                                    <h5>Información personal</h5>
                                </div>
                            */}
                        </div>
                        <div className="row">
                            {
                                tabs[0] ?
                                    <TabLaboral
                                        alias={alias}
                                        puestos={puestos}
                                        onChangeLaboral={onChangeLaboral}
                                        changeAlias={changeAlias}
                                        changeArea={changeArea}
                                        areas={areas}
                                        form={form}
                                        personas={personas}
                                        classes={classes}
                                    />
                                     : void (0)
                            }
                            {
                                tabs[1] ?
                                    <TabSalario classes={classes} formSalario={formSalario} onChangeSalarial={onChangeSalarial} ChangeAliasContrato={ChangeAliasContrato} tipoContratos={tipoContratos} selectTipoContrato={selectTipoContrato} ChangeSelectContrato={ChangeSelectContrato} detalleContratos={detalleContratos} ChangeDetalleContratos={ChangeDetalleContratos} selectDetalleContratos={selectDetalleContratos}/>: void (0)
                            }
                            {
                                /*
                                tabs[2] ?
                                    <div className="mt-4 r-opacity col">
                                        Próximamente
                                    </div> : void (0)
                                */
                            }
                        </div>
                        <div className="text-right mt-3">
                            <button onClick={onSubmit} className="btn-rhea-alternative">Guardar Información</button>
                        </div>
                    </div>
                </div>
            </div>
            <NavRHEA />
            <Notification />
        </div>

    </Fragment>
);
export default Atraccion;