import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalError = ({ mostrar, ocultarModal, mensaje, title="¡Lo sentimos!" }) => {
    return (
        <Fragment>
            <Modal show={mostrar} onHide={ocultarModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div>
                        <h3 className="text-center mt-4 mb-3">{title}</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <h5 className="text-justify mb-4">{mensaje}</h5>
                        <div className="text-right">
                            <button onClick={ocultarModal} className="button-oma">OK</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default ModalError;