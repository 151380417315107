import Solicitud from '../async/solicitud';

async function hookGeneral(form, file){
    let body = { empleado_general: form };
    let solicitud = new Solicitud("POST", body, 1, "empleado_general.php");
    let data = await solicitud.exec();
    if ( data.esExitosa ) {
        solicitud = new Solicitud("POST", file, 1, "photos.php");
        await solicitud.upload( data.datos.idPersonal );
    }
    return data;
}

export default hookGeneral;