import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';

const AvatarUser = ({path, name}) =>{
    useEffect(
        () =>{

        },[]
    )
    return(
        <div className="w-100 d-flex justify-content-center">
            <Avatar alt={name} src={path}  />
        </div>
    )
};

export default AvatarUser;