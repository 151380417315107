import React, { useEffect, useState } from 'react';
import Request from '../async/request';
import Atraccion from '../pages/Atraccion';
import useStyles from '../theme/ThemeInput';
import DateFormatFix from '../utils/DateFormatFix';
const AtraccionContainer = () => {
    const [nuevos, setNuevos] = useState([]);
    const [seleccion, setSeleccion] = useState({idPersonal:'', nombre:'SELECCIONAR', apellidoPaterno:'', apellidoMaterno:''});
    const classes = useStyles();
    const [tabs, setTabs] = useState([1, 0, 0]);
    const [alias, setAlias] = useState([]);
    const [form, setForm] = useState({
        idPersonal: '',
        fechaIngreso: new DateFormatFix(new Date()).getDate(),
        alias: '',
        area: '',
        puesto: '',
        idPersonalJefe: '',
        correoEmpresarial: '',
        ubicacion: '',
        lugarAsignado: ''
    }); 
    const [modal, setModal] = useState({ show: false, title: '', body: '', loading: false });
    const [areas, setAreas] = useState([]);
    const [puestos, setPuestos] = useState([]);
    const [personas, setPersonas] = useState([]);
    const [formSalario, setFormSalario] = useState({sueldoBruto: '', idPersonal: '', id_DesgloseContrato: ''});
    const [tipoContratos, setTipoContratos] = useState([]);
    const [selectTipoContrato, setSelectTipoContrato] = useState(null);
    const [detalleContratos, setDetalleContratos] = useState([]);
    const [selectDetalleContratos, setSelectDetalleContratos] = useState(null);
    
    useEffect(
        () => {
            async function fetchData() {
                let request = new Request("GET", null, 1, "control/empleado_general/atraccion");
                let response = await request.exec();
                if (response.isSuccess) {
                    setNuevos(response.data);
                }
                request = new Request("GET", null, 1, "control/empleado_general");
                response = await request.exec();
                if (response.isSuccess) {
                    setPersonas(response.data);
                }
                request = new Request("GET", null, 1, "mapper/puestos");
                response = await request.exec();
                if (response.isSuccess) {
                    setAlias(response.data);
                }
            }
            fetchData();
        }, []
    )

    const onChange = (item) => {
        if (item) {
            setSeleccion(item);
            setForm({ ...form, idPersonal: item.idPersonal });
            //setFormSalario({...formSalario, idPersonal : item.idPersonal });
        }
    }

    const changeTab = (e) => {
        let newTabs = [0, 0, 0];
        newTabs[parseInt(e.target.id) - 1] = 1
        setTabs(newTabs);
    }

    const changeAlias = async (e) => {
        setForm({ ...form, alias: e.target.value, area: '', puesto: '' });
        let body = { alias: e.target.value };
        let request = new Request("POST", body, 1, "mapper/puestos/area");
        let response = await request.exec();
        if (response.isSuccess) {
            setAreas(response.data);
        }
    }

    const changeArea = async (e) => {
        setForm({ ...form, area: e.target.value, puesto: '' });
        let body = { area: e.target.value, alias: form.alias };
        let request = new Request("POST", body, 1, "mapper/puestos/puesto");
        let response = await request.exec();
        if (response.isSuccess) {
            setPuestos(response.data);
        }
    }

    const onChangeLaboral = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const onChangeSalarial = (e) => {
        setFormSalario({...formSalario, [e.target.name] : e.target.value});
    }

    const ChangeAliasContrato = async(e) => {
        if(e.target.value === null){
            setTipoContratos([]);
        }else{
            let request = new Request( "GET", null, 0, "service/getTipoContratos/" + e.target.value );
            let response = await request.exec();
            if(response.data.length !== 0 ){
                setTipoContratos(response.data);
            }else{
                setTipoContratos([]);
            }
        }
        
    }

    const ChangeSelectContrato = async(value) => {
        if(value === null){
            setSelectTipoContrato(null);
        }else{
            setSelectTipoContrato(value);
            let body = {"id_TipoContrato" : value["id_TipoContrato"]};
            let request = new Request( "POST", body, 0, "service/getDesgloseContratos" );
            let response = await request.exec();
            if(response.data.length !== 0 ){
                setDetalleContratos(response.data);
            }else{
                setDetalleContratos([]);
            }
        }
        
    }

    const ChangeDetalleContratos = (value) => {
        if(value === null){
            setSelectDetalleContratos(null);
            setFormSalario({...formSalario, id_DesgloseContrato: ''});
        }else{
            setSelectDetalleContratos(value);
            setFormSalario({...formSalario, id_DesgloseContrato: value["id_DesgloseContrato"]});
        }
        
    }

    const onSubmit = async () => {
        setModal({ ...modal, loading: true });

        const laborales = [["fechaIngreso", "su fecha de ingreso"], ["alias", "su línea de negocio"], ["area", "su aŕea"], ["puesto", "su puesto"], ["idPersonalJefe", "su jefe inmediato"], ["ubicacion", "su ubicación"], ["lugarAsignado", "su lugar asignado"]];
        const salarios = [["sueldoBruto", "su sueldo bruto"], ["id_DesgloseContrato", "su contrato"]];

        let validateLaboral = validate(laborales, form);
        let validateSalario = validate(salarios, formSalario);

        //TODO : HACEMOS LA VALIDACION
        if(validateLaboral === true && validateSalario === true){
            //TODO: GUARDAMOS FECHA DE ANTIGUEDAD
            let bodyUpdate = {
                attribute:"fechaAntiguedad",
                value: form.fechaIngreso,
                idPersonal: form.idPersonal
            };
            let request = new Request( "PUT", bodyUpdate, 1, "control/empleado_general" );
            let response = await request.exec();

            //TODO: GUARDAMO INFO LABORAL
            let body = { empleado_laboral: { ...form } };
            request = new Request("POST", body, 1, "control/empleado_laboral");
            response = await request.exec();

            if (response.isSuccess) {
                //TODO: BUSCAMOS A LA PERSONA
                let getId = new Request("GET", null, 0, "service/get_empleado/"+form.idPersonal);
                let responseId = await getId.exec();
                let temp_id = responseId.data[0]["idPersonal"];
                setFormSalario({...formSalario, idPersonal : temp_id});

                //TODO: GUARDAMOS INFO SALARIAL
                let bodySalario = { sueldoBruto: formSalario.sueldoBruto, idPersonal: temp_id, id_DesgloseContrato: formSalario.id_DesgloseContrato};
                request = new Request("POST", bodySalario, 1, "service/empleado_salario");
                response = await request.exec();
                setModal({
                    show: true,
                    title: "Le damos la bienvenida a " + seleccion.nombre + " " + seleccion.apellidoPaterno,
                    body: "A partir de ahora puede acceder a la plataforma.",
                    loading: false
                });
                setForm({
                    idPersonal: '',
                    fechaIngreso: new DateFormatFix(new Date()).getDate(),
                    alias: '',
                    area: '',
                    puesto: '',
                    idPersonalJefe: '',
                    correoEmpresarial: '',
                    ubicacion: '',
                    lugarAsignado: ''
                });
                setFormSalario({sueldoBruto: '', idPersonal: '', id_DesgloseContrato: ''});
                setSeleccion({idPersonal:'', nombre:'SELECCIONAR', apellidoPaterno:'', apellidoMaterno:''});
                setTipoContratos([]);
                setSelectTipoContrato(null);
                setDetalleContratos([]);
                setSelectDetalleContratos(null);
                request = new Request("GET", null, 1, "control/empleado_general/atraccion");
                response = await request.exec();
                if (response.isSuccess) {
                    setNuevos(response.data);
                }
            }
            else {
                setModal({
                    show: true,
                    title: "Vaya parece que algo anda mal",
                    body: seleccion.nombre + " " + seleccion.apellidoPaterno + " no pudo ser asignado a su posición, favor de ponerte en contacto con el administrador.",
                    loading: false
                });
            }
        }
    }

    const validate = (array, dict) => {
        let element = null;
        for (let i = 0; i < array.length; i++) {
            element = array[i];
            if (!dict[element[0]]) {
                alert("Debe ingresar "+element[1] + " para poder continuar");
                /*
                setTitle("¡Atención!");
                setBody("Debe ingresar " + element[1] + " para poder continuar");
                setShow(true);
                */
                return false;
            }
        }
        return true;
    }

    const onHide = () => setModal({ ...modal, show: false });

    return (
        <Atraccion
            form={form}
            onChangeLaboral={onChangeLaboral}
            puestos={puestos}
            areas={areas}
            changeArea={changeArea}
            changeAlias={changeAlias}
            alias={alias}
            changeTab={changeTab}
            tabs={tabs}
            seleccion={seleccion}
            onChange={onChange}
            classes={classes}
            nuevos={nuevos}
            personas={personas}
            onSubmit={onSubmit}
            modal={modal}
            onHide={onHide}
            formSalario={formSalario}
            onChangeSalarial={onChangeSalarial}
            ChangeAliasContrato={ChangeAliasContrato}
            tipoContratos={tipoContratos}
            selectTipoContrato={selectTipoContrato}
            ChangeSelectContrato={ChangeSelectContrato}
            detalleContratos={detalleContratos}
            ChangeDetalleContratos={ChangeDetalleContratos}
            selectDetalleContratos={selectDetalleContratos}
        />
    )
};

export default AtraccionContainer;