import { TextField, Fab, Button, InputAdornment } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useStyles from '../../theme/ThemeInput';
import React, { Fragment, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import bsCustomFileInput from 'bs-custom-file-input';
import ModalINE from '../Modal/ModalINE';

const FormularioDocumentos = ({ 
    btnText="Enviar", 
    form, 
    onChange, 
    onLoad, 
    handleSubmit,
    rfc,
    curp,
    acta,
    nss,
    ine,
    comprobante,
    estudios,
    cv,
    infonavit,
    fonacot,
    recomendacion,
    estadoCuenta,
    certificaciones,
    idProyecto
}) => {
    useEffect(
        () => {
            bsCustomFileInput.init()
        }, []
    )
    const [modalINE, setModalINE] = useState(false);
    const classes = useStyles();

    

    const show = () =>{
        setModalINE(true);
    }

    return (
        <Fragment>
            <ModalINE mostrar={modalINE} ocultarModal={() => setModalINE(false) } />
            <div className="container-form-rhea form-docs">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">
                    <div className="row row-eq-height">
                        <div className="col-md-4">
                            <Form.Group controlId="documentos-rfc">
                                <TextField className={classes.textField} variant="outlined" label="RFC" required type="text" name="rfc" value={form.rfc} inputProps={{ maxLength: 13, minLength: 13 }} onChange={onChange} />
                            </Form.Group>
                        </div>

                        <div className="col-md-4">
                            <Form.Group controlId="documentos-curp">
                                <TextField className={classes.textField} variant="outlined" label="CURP" required type="text" name="curp" value={form.curp} inputProps={{ maxLength: 18, minLength: 18 }} onChange={onChange} />
                            </Form.Group>
                        </div>

                        <div className="col-md-4">
                            <Form.Group controlId="documentos-nss">
                                <TextField className={classes.textField} variant="outlined" label="NSS" inputProps={{ maxLength: 11, minLength: 11 }} required type="text" name="nss" value={form.nss} onChange={onChange} />
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-4">
                            <Form.Group controlId="documentos-ine">
                                <div className="container-input-prefix">
                                    <TextField className={classes.textField} variant="outlined" label="Número de INE"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">IDMEX</InputAdornment>,
                                        }}
                                        type="text" name="numeroINE" inputProps={{ maxLength: 12 }} value={form.numeroINE} onChange={onChange} />
                                </div>
                                <div className="text-center mt-2">
                                    <div role="button" onClick={show} className="text-form button-link">¿Dónde encuentro mi número de INE?</div>
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-md-4">
                            <Form.Group controlId="documentos-infonavit">
                                <TextField className={classes.textField} variant="outlined" label="Número de crédito INFONAVIT" inputProps={{ maxLength: 15 }} type="text" name="numeroINFONAVIT" value={form.numeroINFONAVIT} onChange={onChange} />
                                <div className="text-center mt-2">
                                    <div className="text-form button-link">Si aplica</div>
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-md-4">
                            <Form.Group controlId="documentos-fonacot">
                                <TextField className={classes.textField} variant="outlined" label="Número de crédito FONACOT" inputProps={{ maxLength: 15 }} type="text" name="numeroFONACOT" value={form.numeroFONACOT} onChange={onChange} />
                                <div className="text-center mt-2">
                                    <div className="text-form button-link">Si aplica</div>
                                </div>
                            </Form.Group>
                        </div>

                    </div>

                    <div className="row mt-5 row-eq-height justify-content-center">
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-cv-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="cvFile"  type="file" accept=".pdf" name="cvFile" onChange={onLoad} />
                                    <label htmlFor="cvFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu Curriculum Vitae
                                        </Button>
                                    </label>
                                    <Form.Text className="text-muted">
                                        {cv ? cv.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-acta-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="actaFile"  type="file" accept=".pdf" name="actaFile" onChange={onLoad} />
                                    <label htmlFor="actaFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu acta de nacimiento
                                        </Button>
                                    </label>
                                    <Form.Text className="text-muted">
                                        {acta ? acta.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-comprobante-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="estudiosFile"  type="file" accept=".pdf" name="estudiosFile" onChange={onLoad} />
                                    <label htmlFor="estudiosFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu comprobante de estudios
                                        </Button>
                                    </label>
                                    <Form.Text className="text-muted">
                                        {estudios ? estudios.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-comprobante-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="comprobanteFile"  type="file" accept=".pdf" name="comprobanteFile" onChange={onLoad} />
                                    <label htmlFor="comprobanteFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu comprobante de domicilio
                                        </Button>
                                    </label>
                                    <div>
                                        <p className="text-form">No mayor a 2 meses (luz, agua, teléfono fijo o cable)</p>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {comprobante ? comprobante.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-estadoCuenta-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="estadoCuentaFile"  type="file" accept=".pdf" name="estadoCuentaFile" onChange={onLoad} />
                                    <label htmlFor="estadoCuentaFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir estado de cuenta
                                        </Button>
                                    </label>
                                    <div>
                                        <p className="text-form">Formato pdf y legible</p>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {estadoCuenta ? estadoCuenta.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-rfc-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="rfcFile"  type="file" accept=".pdf" name="rfcFile" onChange={onLoad} />
                                    <label htmlFor="rfcFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu constancia de situación fiscal
                                        </Button>
                                    </label>
                                    <div>
                                        <a href="https://www.sat.gob.mx/aplicacion/login/43824/reimprime-tus-acuses-del-rfc" rel="noopener noreferrer" target="_blank">Obten aquí tu constancia</a>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {rfc ? rfc.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-curp-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="curpFile"  type="file" accept=".pdf" name="curpFile" onChange={onLoad} />
                                    <label htmlFor="curpFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu CURP
                                        </Button>
                                    </label>
                                    <div>
                                        <a href="https://www.gob.mx/curp/" rel="noopener noreferrer" target="_blank">Obten aquí tu CURP</a>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {curp ? curp.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-nss-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="nssFile"  type="file" accept=".pdf" name="nssFile" onChange={onLoad} />
                                    <label htmlFor="nssFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu NSS
                                        </Button>
                                    </label>
                                    <div>
                                        <a href="http://www.imss.gob.mx/derechoH/nss" rel="noopener noreferrer" target="_blank">Obten aquí tu NSS</a>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {nss ? nss.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-ine-path">
                                <div className="text-center">
                                    <input style={{ display: 'none' }} id="ineFile"  type="file" accept=".pdf" name="ineFile" onChange={onLoad} />
                                    <label htmlFor="ineFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu INE o Pasaporte
                                        </Button>
                                    </label>
                                    <div>
                                        <p className="text-form">Escáner de tu INE por ambos lados</p>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {ine ? ine.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-infonavit-path">
                                <div className="text-center">
                                    <input style={{display: 'none'}} id="recomendacionFile" type="file" accept=".pdf" name="recomendacionFile" onChange={onLoad} />
                                    <label htmlFor="recomendacionFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tus cartas de recomendación
                                        </Button>
                                    </label>
                                    <div>
                                        <p className="text-form">Todas escaneadas en un solo documento en PDF</p>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {recomendacion ? recomendacion.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-md-3">
                            <Form.Group controlId="documentos-infonavit-path">
                                <div className="text-center">
                                    <input style={{display: 'none'}} id="infonavitFile" type="file" accept=".pdf" name="infonavitFile" onChange={onLoad} />
                                    <label htmlFor="infonavitFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu estado de cuenta INFONAVIT
                                        </Button>
                                    </label>
                                    <div>
                                        <a href="https://micuenta.infonavit.org.mx/" rel="noopener noreferrer" target="_blank">Obten aquí tu estado de cuenta INFONAVIT</a>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {infonavit ? infonavit.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        
                        <div className="col-md-3">
                            <Form.Group controlId="documentos-fonacot-path">
                                <div className="text-center">
                                    <input style={{display: 'none'}} id="fonacotFile" type="file" accept=".pdf" name="fonacotFile" onChange={onLoad} />
                                    <label htmlFor="fonacotFile">
                                        <Button className="button-send button-upload" component="span">
                                            <CloudUploadIcon className="icon-rhea mr-2" />
                                            Subir tu comprobante de crédito FONACOT
                                        </Button>
                                    </label>
                                    <div>
                                        <a href="https://servicios.fonacot.gob.mx/" rel="noopener noreferrer" target="_blank">Obten aquí tu estado de cuenta FONACOT</a>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {fonacot ? fonacot.name : ""}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </div>
                        {
                            idProyecto === '1' ? (
                                <div className="col-md-3">
                                    <Form.Group controlId="documentos-certificados-path">
                                        <div className="text-center">
                                            <input style={{ display: 'none' }} id="certificaciones"  type="file" accept=".pdf" name="certificaciones" onChange={onLoad}/>
                                            <label htmlFor="certificaciones">
                                                <Button className="button-send button-upload" component="span">
                                                    <CloudUploadIcon className="icon-rhea mr-2" />
                                                    Subir tus certificaciones:  BLS/ACLS/PHTLS/PALS
                                                </Button>
                                            </label>
                                            <div>
                                                <p>Ingresa un solo archivo pdf</p>
                                            </div>
                                            <Form.Text className="text-muted">
                                                {certificaciones ? certificaciones.name : ""}
                                            </Form.Text>
                                        </div>
                                    </Form.Group>
                                </div>
                            ) : void (0)
                        }
                    </div>


                    <div className="mt-4 text-center">
                        <Fab type="submit" className="button-send" variant="extended">
                            <NavigationIcon />
                            {btnText}
                        </Fab>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default FormularioDocumentos;