import React, { Fragment } from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';
import { Checkbox, FormGroup, FormControlLabel, Select, FormControl, InputLabel,TextField, List, ListItem, ListItemText, IconButton} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import Information from '../components/Modal/ModalError';

const Cursos = ({show, title, body, CerrarModal, todos, alias, classes, ChangeEmpresa, personas, selectPersona, seleccionPersonas, ChangeBox, ChangePersona, DeletePersona, formato, ChangeFormato, formDC4, ChangeForm, formCurso, ChangeFormCurso, FormSubmit, ChangeFormDC3, formDC3, colaboradores, VerificaCorreo, isError, areas,changeAreaCurso, selectArea}) => {
  return (
    <div className="container-principal">
       <Information mensaje={body} title={title} mostrar={show} ocultarModal={CerrarModal} />
      <div className="info-container">
          <div className="row">
            <div className='col-md-12 mt-3'>
              <h4>Empresa y formato a crear</h4>
              <hr></hr>
            </div>
            <div className='col-md-6 mt-3'>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel>Empresa</InputLabel>
                <Select value={alias} label="Selecciona la empresa" onChange={ChangeEmpresa} required native as="select">
                  <option value=""></option>
                  <option value="KAMBERG">KAMBERG</option>
                  <option value="ATLANTIS">ATLANTIS</option>
                  <option value="TAMMAMED">TAMMAMED</option>
                  <option value="AMHI">AMHI</option>
                  <option value="ROCKETS">ROCKETS</option>
                </Select>
              </FormControl>
            </div>
            <div className='col-md-6 mt-3'>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel>Formato a crear</InputLabel>
                <Select value={formato} label="Selecciona la empresa" onChange={ChangeFormato} required native as="select">
                  <option value=""></option>
                  <option value="DC4">DC - 4</option>
                  <option value="DC3">DC - 3</option>
                </Select>
              </FormControl>
            </div>
            {
              formato === "DC4" ? 
              <Fragment>
                <div className='col-md-12 mt-3'>
                  <h4>Formato DC - 4</h4>
                  <hr></hr>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="date" variant="outlined" label="Periodo de Vigencia Inicio" required id="vigenciaInicio" value={formDC4.vigenciaInicio} onChange={ChangeForm} inputProps={{ autoComplete: "off"}}/>
                </div>
                <div className='col-md-6 mt-3'>
                    <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="date" variant="outlined" label="Periodo de Vigencia Fin" required id="vigenciaFin" value={formDC4.vigenciaFin} onChange={ChangeForm} inputProps={{ autoComplete: "off"}}/>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField error={isError} className={classes.textField} id="correoContacto" variant="outlined" label="Correo de contacto" value={formDC4.correoContacto} onChange={ChangeForm} type="email" onBlur={VerificaCorreo} inputProps={{ autoComplete: "off"}}/>
                  <p id="text-invalidEmail" className='text-danger'></p>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField type="tel" className={classes.textField} id="telefonoContacto" variant="outlined" label="Teléfono de contacto" value={formDC4.telefonoContacto} onChange={ChangeForm} inputProps={{ maxLength: 10,pattern:"[0-9]{10}",autoComplete: "off", title:"Ingresa sólo los 10 dígitos de tu número celular"}} />
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} id="nombreNorma" variant="outlined" label="Nombre de la norma o estándar *" value={formDC4.nombreNorma} onChange={ChangeForm} type="text" inputProps={{ autoComplete: "off"}} helperText="Datos no obligatorios"/>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} id="fechaEmisionCertificado" variant="outlined" label="Fecha de emisión del Certificado *" value={formDC4.fechaEmisionCertificado} onChange={ChangeForm} type="date" inputProps={{ autoComplete: "off"}} helperText="Datos no obligatorios" InputLabelProps={{ shrink: true}}/>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} id="lugarExpedicion" variant="outlined" label="Lugar de Expedición" value={formDC4.lugarExpedicion} onChange={ChangeForm} type="text" inputProps={{ autoComplete: "off"}}/>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="date" variant="outlined" label="Fecha de expedición del documento" required id="fechaExpedicion" value={formDC4.fechaExpedicion} onChange={ChangeForm} inputProps={{ autoComplete: "off"}}/>
                </div>
              </Fragment>
              : (formato === "DC3") ? 
              <Fragment>
                <div className='col-md-12 mt-3'>
                  <h4>Formato DC - 3</h4>
                  <hr></hr>
                </div>
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="text" variant="outlined" label="Representante de los trabajadores" required id="representanteTrabajadores" value={formDC3.representanteTrabajadores} onChange={ChangeFormDC3} inputProps={{ autoComplete: "off"}}/>
                </div>
              </Fragment>
              : void(0)
            }
          </div>
          <div className="row mt-4">
            <div className='col-md-12 mt-3'>
              <h4>Datos del Curso</h4>
              <hr></hr>
            </div>
            <div className='col-md-6 mt-3'>
              <TextField className={classes.textField} id="nombreCurso" variant="outlined" label="Nombre del curso" value={formCurso.nombreCurso} onChange={ChangeFormCurso} type="text" inputProps={{ autoComplete: "off"}}/>
            </div>
            <div className='col-md-6 mt-3'>
              <TextField className={classes.textField} id="horasCurso" variant="outlined" label="Duración del curso en horas" value={formCurso.horasCurso} onChange={ChangeFormCurso} type="text" inputProps={{ autoComplete: "off"}}/>
            </div>
            <div className='col-md-6 mt-3'>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel>Modalidad del curso</InputLabel>
                <Select id="modalidadCurso" value={formCurso.modalidadCurso} label="Modalidad del curso" onChange={ChangeFormCurso} required native as="select">
                  <option value=""></option>
                  <option value="1">1.- Presencial</option>
                  <option value="2">2.- En línea</option>
                  <option value="3">3.- mixta</option>
                </Select>
              </FormControl>
            </div>
            <div className='col-md-6 mt-3'>
                <FormControl className={classes.textField} variant="outlined">
                  <Autocomplete
                    id="areas"
                    options={areas}
                    value={selectArea}
                    getOptionLabel={(option) => option.clave_curso + " " + option.descripcion_curso}
                    onChange={(option, value) => changeAreaCurso(value)}
                    getOptionSelected={(option, value) => true }
                    renderInput={(params) => <TextField className={classes.textField} {...params} label="Areás temáticas de los cursos" variant="outlined" />}
                  />
                </FormControl>
              { /*<TextField className={classes.textField} id="areaTematica" variant="outlined" label="Área temática del curso" value={formCurso.areaTematica} onChange={ChangeFormCurso} type="text" inputProps={{ autoComplete: "off"}}/> */ }
            </div>
            <div className='col-md-6 mt-3'>
              <TextField className={classes.textField} id="nombreAgente" variant="outlined" label="Nombre del agente capacitador o STPS" value={formCurso.nombreAgente} onChange={ChangeFormCurso} type="text" inputProps={{ autoComplete: "off"}}/>
            </div>
            <div className='col-md-6 mt-3'>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel>Tipo de agante capacitador</InputLabel>
                <Select id="tipoAgente" value={formCurso.tipoAgente} label="Selecciona la empresa" onChange={ChangeFormCurso} required native as="select">
                  <option value=""></option>
                  <option value="1">Interno</option>
                  <option value="2">Externo</option>
                  <option value="3">Otro</option>
                </Select>
              </FormControl>
            </div>
            {
              formCurso.tipoAgente === "2" ? 
                <div className='col-md-6 mt-3'>
                  <TextField className={classes.textField} id="numeroRegistroAgente" variant="outlined" label="Numero de Registro de agente capacitador externo ante la STPS" value={formCurso.numeroRegistroAgente} onChange={ChangeFormCurso} type="text" inputProps={{ autoComplete: "off"}}/>
                </div>
              : void(0)
            }
            <div className='col-md-6 mt-3'>
              <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="date" variant="outlined" label="Fecha inicio de Ejecución" required id="fechaInicioEjec" value={formCurso.fechaInicioEjec} onChange={ChangeFormCurso} inputProps={{ autoComplete: "off"}}/>
            </div>
            <div className='col-md-6 mt-3'>
              <TextField className={classes.textField} InputLabelProps={{ shrink: true}} type="date" variant="outlined" label="Fecha fin de Ejecución" required id="fechaFinEjec" value={formCurso.fechaFinEjec} onChange={ChangeFormCurso} inputProps={{ autoComplete: "off"}}/>
            </div>
            <div className='col-md-6 mt-3'>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel>Objetivo del curso</InputLabel>
                <Select id="objetivoCurso" value={formCurso.objetivoCurso} label="Selecciona un objetivo" onChange={ChangeFormCurso} required native as="select">
                  <option value=""></option>
                  <option value="1">1.- Actualizar y perfeccionar conocimientos y habilidades y proporcionar información de nuevas tecnologías</option>
                  <option value="2">2.- Prevenir riesgos de trabajo</option>
                  <option value="3">3.- Incrementar la productividad</option>
                  <option value="4">4.- Mejorar el nivel educativo</option>
                  <option value="5">5.- Preparar para ocupar vacantes o puestos de nueva creación</option>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row mt-4">
          <div className='col-md-12 mt-3'>
              <h4>Colaboradores</h4>
              <hr></hr>
            </div>
            <div className='col-md-4 mt-3'>
              {
                !todos ?
                  <FormControl className={classes.textField} variant="outlined">
                    <Autocomplete
                      id="Personas"
                      options={colaboradores}
                      value={selectPersona}
                      getOptionLabel={(option) => option.nombre + " " + option.apellidoPaterno + " " + option.apellidoMaterno}
                      onChange={(option, value) => ChangePersona(value)}
                      renderInput={(params) => <TextField className={classes.textField} {...params} label="Colaboradores" variant="outlined" />}
                    />
                  </FormControl>
                : void(0)
              }
            </div>
            <div className='col-md-4 mt-4'>
              <FormGroup>
                <FormControlLabel
                  label={`Seleccionar todos los colaboradores de ${alias}`}
                  control={
                    <Checkbox
                      checked={todos}
                      onChange={ChangeBox}
                      color="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className='col-md-4 mt-4'>
              <button onClick={FormSubmit} type="button" className='btn-rhea-alternative'> Generar Constancias</button>
            </div>
          </div>
          <div className="row mt-4">
            <h5>Personas seleccionadas:</h5>
              {   
                seleccionPersonas.length !== 0 ?
                <div className='col-md-4 col-sm-12 col-xs-12 col-lg-4'>
                  <List disablePadding={false} dense={true}>
                    {
                      seleccionPersonas.map(
                        (persona) => (
                        <ListItem key={persona.idPersonal}>
                          <ListItemText primary={`${persona.nombre} ${persona.apellidoPaterno} ${persona.apellidoMaterno} `}/>
                            <IconButton aria-label="delete" onClick={() => DeletePersona(persona.idPersonal)}>
                              <DeleteIcon />
                            </IconButton>
                        </ListItem>
                      ))
                    }
                  </List>
                </div> : void(0)
              }                  
          </div>
      </div>
      <NavRHEA />
      <Notification />
    </div>
  );
}

export default Cursos;
