import { TextField, Fab } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import useStyles from '../../theme/ThemeInput';
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const FormularioBanco = ({btnText="Enviar", form, onChange, onChangeClabe, handleSubmit}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <div className="container-form-rhea">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">
                    <div className="row">
                        <div className="col-md-7">
                            <Form.Group controlId="banco-clabe">
                                <TextField className={classes.textField} value={form.clabe} variant="outlined" label="CLABE Interbancaria" required type="text" name="clabe" inputProps={{autoComplete:"off", minLength: 18, maxLength: 18}} onChange={onChangeClabe}/>
                                <Form.Text className="text-muted">
                                    Primero ingrese su clabe
                                </Form.Text>
                            </Form.Group>
                        </div>
                        <div className="col-md-5">
                            <Form.Group controlId="banco-nombre">
                                <TextField className={classes.textField} variant="outlined" label="Nombre del banco" required type="text" disabled name="banco" value={form.banco}/>
                            </Form.Group>
                        </div>
                        <div className="col-md-5">
                            <Form.Group controlId="banco-cuenta">
                                <TextField className={classes.textField} value={form.cuenta} inputProps={{ maxLength: 12, minLength: 10 }} variant="outlined" label="Cuenta" required type="text" name="cuenta" onChange={onChange}/>
                            </Form.Group>
                        </div>
                        <div className="col-md-7">
                            <Form.Group controlId="banco-cuentahabiente">
                                <TextField className={classes.textField} value={form.nombre} variant="outlined" label="Nombre cuentahabiente" required type="text" name="nombre" onChange={onChange}/>
                            </Form.Group>
                        </div>

                    </div>

                    <div className="mt-4 text-center">
                        <Fab type="submit" className="button-send" variant="extended">
                            <NavigationIcon />
                            {btnText}
                        </Fab>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default FormularioBanco;