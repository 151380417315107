import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from '../../theme/ThemeInput';
import { Spinner } from 'react-bootstrap';
import Request from '../../async/request';


const ModalClave = ({ mostrar, ocultarModal, id }) => {
    const classes = useStyles();
    const [form, setForm] = useState({clave: '', claveConfirma:'', show:false, msg: '', loading: false});

    const handleSubmit = async () =>{
        setForm({...form, loading:true });
        if( form.clave === form.claveConfirma && form.clave.length > 5 ){
            let body = { id: id, clave: form.clave};
            let request = new Request("POST", body, 1, "service/usuario/cambiar");
            let response = await request.exec();
            if( response.isSuccess )
                setForm({...form, loading:true, msg: 'La contraseña se actualizó, en breve se cerrará esta ventana...'});
            else
                setForm({...form, loading:true, msg: 'La contraseña no se pudo actualizar, el próximo inicio de sesión lo intentaremos nuevamente. En breve se cerrará esta ventana...'});
            setTimeout( () => ocultarModal(), 2000 );
        }
        else{
            setForm({...form, msg: 'Las contraseñas no coincide o no tienen la longitud mínima requerida'});
        }
    }

    return (
        <Fragment>
            <Modal
                show={mostrar}
                onHide={ocultarModal}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="text-center text-bold">Esto es por tu seguridad</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <p className="text-bold">Necesitamos que cambies tu clave para garantizar la seguridad de tu cuenta. Debe contener al menos 6 caracteres alfanumericos</p>
                    <div className="m-4">
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            className={classes.textField}
                            id="clave"
                            type={form.show ? 'text' : 'password'}
                            value={form.clave}
                            onChange={({ target }) => setForm({ ...form, clave: target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setForm({ ...form, show: !form.show })}
                                        edge="end"
                                    >
                                        {form.show ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Clave"
                        />
                    </div>
                    <div className="m-4">
                        <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
                        <OutlinedInput
                            className={classes.textField}
                            id="confirma-clave"
                            type={form.show ? 'text' : 'password'}
                            value={form.claveConfirma}
                            onChange={({ target }) => setForm({ ...form, claveConfirma: target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setForm({ ...form, show: !form.show })}
                                        edge="end"
                                    >
                                        {form.show ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Clave"
                        />
                    </div>
                    <div className="text-center">
                        <p className="text-bold">{form.msg}</p>
                    </div>
                    <div className="text-center">
                        {
                            form.loading ? 
                                <Spinner animation="border" />
                                :
                                <button type="button" onClick={handleSubmit} className="btn-rhea-alternative">Actualizar</button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default ModalClave;