import React, { Fragment } from 'react';
import OrganizationChart from "@dabeng/react-orgchart";
import NavRHEA from "../components/Others/NavRHEA";

const Organigrama = ({orgChart, clickNode, org}) =>(
    <Fragment>
        <div className="container-principal">
            <div className="info-container">
                <div>
                    <h1 className="mb-4 text-center">Organigrama</h1>
                </div>
                <OrganizationChart id="org-chart" chartClass="sekure-org-chart" onClickNode={clickNode} ref={orgChart} datasource={org} />
            </div>
            <NavRHEA />
        </div>
    </Fragment>
);

export default Organigrama;