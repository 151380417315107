import React, { Fragment, useEffect, useState } from 'react';
import AvatarUser from '../Others/AvatarUser';
import Request from '../../async/request';

const CardNuevo = ({seleccion}) => {
    const [foto, setFoto] = useState(null);

    useEffect(
        () =>{
            async function get() {
                let request = new Request("POST", null, 1, "service/descarga/foto/" + seleccion.idPersonal );
                let response = await request.blob();
                let img = URL.createObjectURL( response );
                setFoto( img );
            }
            if( seleccion.idPersonal !== "" )
                get();
        },[seleccion]
    )
    return (
        <Fragment>
            <div className="nuevos-foto mt-4">
                <AvatarUser path={foto} name={seleccion.nombre} />
            </div>
            <div>
                <p className="text-center mt-5">{seleccion.nombre} {seleccion.apellidoPaterno} {seleccion.apellidoMaterno}</p>
            </div>
            <div className="text-center">
                <button className="btn-rhea-alternative mt-3">Ver CV</button>
            </div>
        </Fragment>
    )
};

export default CardNuevo;