import React, { useEffect, useState } from 'react';
import Request from '../../async/request';
import DateFormatFix from '../../utils/DateFormatFix';

const CardSolicitud = ({solicitud, flag=false, onClick}) =>{
    const [name, setName] = useState("");
    
    useEffect(
        () =>{
            async function fetchUser(){
                let request = new Request("GET", null, 1, "control/empleado_general/find/" + solicitud.idPersonal );
                let response = await request.exec();
                if( response.isSuccess )
                    setName( response.data.nombre + " " + response.data.apellidoPaterno );
            }
            if( flag )
                fetchUser();
        },[solicitud, flag]
    )

    return(
        <div className="b-sol text-solicitud">
            <div className="row">
                {solicitud.idPersonal ? 
                    <div className={!flag ? "col-md-12"  : "col-lg-12"}>
                        <p><span className="scope">ID Solicitud:</span> {solicitud.idSolicitud}</p>
                        <p><span className="scope">Tipo:</span> {solicitud.tipo}</p>
                        {name ? <p><span className="scope">Nombre:</span> {name}</p> : void(0) }
                        <p><span className="scope">Estatus:</span> {solicitud.estatus}</p>
                        <p><span className="scope">Autoriza:</span> {solicitud.autorizador}</p>
                        <p><span className="scope">Inician:</span> {new DateFormatFix(new Date(solicitud.inicio + "T00:00:00")).getFormatNameFix()}</p>
                        <p><span className="scope">Terminan:</span> {new DateFormatFix(new Date(solicitud.fin + "T00:00:00")).getFormatNameFix()}</p>
                        <p><span className="scope">Días:</span> {solicitud.dias}</p>
                        {!flag ? <p className="text-quote">{new DateFormatFix(new Date(solicitud.fechaSolicitud)).getFormatNameFix()}</p> : void(0)}
                    </div>
                    :
                    void(0)
                }
                { flag ?
                    <div className="col-lg-12 text-center">
                        <button onClick={onClick} name="1" className="mw-200 btn-rhea-alternative mr-3 mt-4">Aceptar</button>
                        <button onClick={onClick} name="0" className="mw-200 btn-rhea-alternative mr-3 mt-3">Rechazar</button>
                        <p className="bottom text-quote">{new DateFormatFix(new Date(solicitud.fechaSolicitud)).getFormatNameFix()}</p>
                    </div>
                    :
                    void(0)
                }
            </div>
        </div>

    )
};

export default CardSolicitud;