import React, { Fragment } from 'react';
import FormularioLogin from '../components/Forms/FormularioLogin';
import Loading from '../components/Loading';

const Login = ({load,hiddenPassword,handleSubmit,onChange,msg,loading}) => {

    if( load )
        return(
            <Loading />
        )

    return (
        <Fragment>
            <div className="containerLogin">
                <FormularioLogin hiddenPassword={hiddenPassword} handleSubmit={handleSubmit} onChange={onChange} msg={msg} loading={loading} />
            </div>
        </Fragment>
    )
}

export default Login;