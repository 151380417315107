import { InputLabel, TextField, Select, FormControl, Fab, IconButton } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import NavigationIcon from '@material-ui/icons/Navigation';
import useStyles from '../../theme/ThemeInput';
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Logo from '../../images/TRIDENT_GIF.gif';

const FormularioGeneral = ({ form, onChange, handleSubmit, btnText="Enviar" }) => {
    const classes = useStyles();

    const changePhoto = (e) => {
        if (e.target.files[0] != null){
            onChange(e);
        }
    }

    return (
        <Fragment>
            <div className="container-form-rhea">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">
                    <div className="d-flex justify-content-center p-5">
                        {
                            form.img ?
                                <img src={form.img} className="imagen-general" alt="Foto" />
                                :
                                <img src={Logo} className="imagen-general" alt="Foto" />
                        }
                    </div>
                    <Form.Group controlId="general-foto">
                        <div className="text-center">
                            <InputLabel >Sube una foto</InputLabel>
                            <input style={{ display: 'none' }} id="foto" type="file" accept="image/*" name="foto" onChange={changePhoto} />
                            <label htmlFor="foto">
                                <IconButton color="primary" component="span">
                                    <PhotoCamera className="icon-rhea-camera" />
                                </IconButton>
                            </label>
                        </div>
                    </Form.Group>

                    <Fragment>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <Form.Group controlId="general-nombre">
                                    <TextField className={classes.textField} variant="outlined" label="Nombre" required type="text" name="nombre" value={form.nombre} inputProps={{ autoComplete: "off" }} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-paterno">
                                    <TextField className={classes.textField} variant="outlined" label="Apellido paterno" required type="text" name="apellidoPaterno" value={form.apellidoPaterno} inputProps={{ autoComplete: "off" }} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-materno">
                                    <TextField className={classes.textField} variant="outlined" label="Apellido materno" required type="text" name="apellidoMaterno" value={form.apellidoMaterno} inputProps={{ autoComplete: "off" }} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-fecha-nacimiento">
                                    <TextField className={classes.textField} variant="outlined" label="Fecha de nacimiento" required type="date" name="fechaDeNacimiento" onChange={onChange} value={form.fechaDeNacimiento} InputLabelProps={{ shrink: true }} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-pais-donde-nacio">
                                    <TextField className={classes.textField} variant="outlined" label="País de nacimiento" required type="text" name="paisDeNacimiento" inputProps={{autoComplete: "off", title: "País de nacimiento" }} value={form.paisDeNacimiento} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-estado-donde-nacio">
                                    <TextField className={classes.textField} variant="outlined" label="Estado de nacimiento" required type="text" name="estadoDeNacimiento" inputProps={{autoComplete: "off", title: "Estado de nacimiento" }} value={form.estadoDeNacimiento} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-curp">
                                    <TextField className={classes.textField} variant="outlined" label="CURP" required type="text" name="curp" inputProps={{ pattern: ".{18}", maxLength: 18, autoComplete: "off", title: "El CURP consta de 18 caracteres" }} value={form.curp} onChange={onChange} />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-estado-civil">
                                    <FormControl className={classes.textField} variant="outlined">
                                        <InputLabel>Estado civil</InputLabel>
                                        <Select native label="Estado civil" required as="select" name="estadoCivil" value={form.estadoCivil} inputProps={{ name: "estadoCivil", id: "estadoCivil" }} onChange={onChange}>
                                            <option />
                                            <option>SOLTERO</option>
                                            <option>CASADO</option>
                                            <option>UNIÓN LIBRE</option>
                                        </Select>
                                    </FormControl>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="general-sexo">
                                    <FormControl className={classes.textField} variant="outlined">
                                        <InputLabel>Sexo</InputLabel>
                                        <Select native label="Sexo" required as="select" name="sexo" value={form.sexo} onChange={onChange} >
                                            <option />
                                            <option>MASCULINO</option>
                                            <option>FEMENINO</option>
                                            <option>COMUNIDAD LGTB+</option>
                                        </Select>
                                    </FormControl>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <Fab type="submit" className="button-send" variant="extended">
                                <NavigationIcon />
                                {btnText}
                            </Fab>
                        </div>
                    </Fragment>

                </Form>
            </div>
        </Fragment>
    )
}
export default FormularioGeneral;