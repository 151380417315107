import React, { Fragment } from 'react';
import GIF from '../images/TRIDENT_GIF.gif';

const Load = () =>(
    <Fragment>
        <div className="container-load text-red">
            <div className="icon-center">
                <img src={GIF} width="150px" alt="Loading..."/>
            </div>
        </div>
    </Fragment>
);

export default Load;