import React, { Fragment } from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import CardSolicitud from '../components/Cards/CardSolicitud';
import ModalError from '../components/Modal/ModalError';
import ModalCarga from '../components/Modal/ModalCarga';

const Resultado = ({loading, operacion, show, onClose, onClick, title, body, solicitud, persona}) =>(
    <Fragment>
        <div className="container-principal">
            <ModalError mostrar={show} ocultarModal={onClose} title={title} mensaje={body} />
            <ModalCarga show={loading} />
            <div className="info-container">
                <div className="text-center mb-4">
                    {
                        solicitud.estatus === "Pendiente" ?
                            operacion ?
                                <h2>Estás a punto de ACEPTAR la siguiente solicitud de {persona.nombre} {persona.apellidoPaterno} {persona.apellidoMaterno}.</h2>
                                :
                                <h2>Estás a punto de RECHAZAR la siguiente solicitud de {persona.nombre} {persona.apellidoPaterno} {persona.apellidoMaterno}.</h2>
                            :
                            <h2>Estatus {solicitud.estatus}</h2>
                    }
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <CardSolicitud solicitud={solicitud} />
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    {
                        solicitud.estatus === "Pendiente" ?
                            <div className="col-md-3">
                                <button onClick={onClick} className="w-100 btn-rhea-alternative">De acuerdo</button>
                            </div>
                            :
                            void(0)
                    }
                </div>
            </div>
            <NavRHEA menu={false} />
        </div>

    </Fragment>
);

export default Resultado;
