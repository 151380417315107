import React, { useEffect, useState } from 'react';
import Request from '../async/request';
import Personal from '../pages/Personal';
import useStyles from '../theme/ThemeInput';

const PersonalContainer = () =>{
    const classes = useStyles();
    const [personal, setPersonal] = useState([]);
    const [seleccion, setSeleccion] = useState(null);

    useEffect(
        () =>{
            async function get() {
                let request = new Request("GET", null, 1, "control/empleado_general");
                let response = await request.exec();
                setPersonal( response.data );
            }
            get();
        },[]
    );

    const onChange = async (value) => setSeleccion( value );

    const baja = async () =>{
        let request = new Request("POST", null, 1, "service/baja/" + seleccion.idPersonal );
        let response = await request.exec();
        request = new Request("GET", null, 1, "control/empleado_general");
        response = await request.exec();
        setPersonal( response.data );
        if( response.isSuccess ){
            setSeleccion({...seleccion, activo: !seleccion.activo});
        }
        
    }

    const descargar = async (path, tipo) =>{
        let body = {
            path: path,
            tipo: tipo,
            nombre: seleccion.nombre + " " + seleccion.apellidoPaterno
        }
        let request = new Request("POST", body, 1, "service/descarga/docs");
        let response = await request.blob();
        console.log( response )
        var file = window.URL.createObjectURL( response );
        window.open( file, "_blank"  );
    }

    return(
        <Personal 
            personal = { personal } 
            classes = { classes }
            seleccion = { seleccion }
            onChange = { onChange }
            descargar = { descargar }
            baja = { baja }
        />
    )
};

export default PersonalContainer;