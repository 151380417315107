import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';
import SectionBank from '../components/Sections/SectionBank';
import SectionDocumentos from '../components/Sections/SectionDocumentos';
import SectionEmergency from '../components/Sections/SectionEmergency';
import SectionFoto from '../components/Sections/SectionFoto';
import SectionUser from '../components/Sections/SectionUser';
import SectionVacaciones from '../components/Sections/SectionVacaciones';
import SectionWork from '../components/Sections/SectionWork';

const Personal = ({ 
    personal, 
    classes, 
    seleccion, 
    onChange,
    descargar,
    baja
}) =>(
    <div className="container-principal">
            <div className="info-container">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        
                    </div>
                    <div className="col-md-3">
                        
                    </div>
                    <div className="col-md-4">
                    <h5>Ingresa el nombre del colaborador:</h5>
                        <Autocomplete
                            id="combo-box-demo"
                            options={personal}
                            value={seleccion}                
                            getOptionLabel={(option) => option.nombre + " " + option.apellidoPaterno + " " + option.apellidoMaterno}
                            onChange={(option, value) => onChange(value)}
                            getOptionSelected={() => true}
                            renderInput={(params) => <TextField className={classes.textField} {...params} label="Buscar" variant="outlined" />}
                        />
                    </div>
                </div>
            </div>
            {
                seleccion !== null ?
                    <div className="info-container mt-4">
                        <div className="row align-items-center">
                            <SectionFoto user={seleccion} personal={true} baja={baja} />
                            <SectionUser idPersonal={seleccion.idPersonal} />
                            <SectionWork idPersonal={seleccion.idPersonal} />
                            <SectionBank idPersonal={seleccion.idPersonal} />
                            <SectionEmergency idPersonal={seleccion.idPersonal} />
                            <SectionVacaciones idPersonal={seleccion.idPersonal} />
                            <SectionDocumentos idPersonal={seleccion.idPersonal} descargar={descargar} />
                        </div>
                    </div>
                    :
                    void (0)
            }
            
            <NavRHEA />
            <Notification />
        </div>
);

export default Personal;