import React, { useState, useEffect } from 'react'
import useStyles from '../theme/ThemeInput';
import Request from '../async/request';
import Contratos from '../pages/Contratos';


const ContratosContainer = () => {
    const classes = useStyles();
    const [todos, setTodos] = useState(false);
    const [form, setForm] = useState({fechaInicio: '', fechaFin: ''});
    const [personas, setPersonas] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [selectProyecto, setSelectProyecto] = useState(null);
    const [selectPersona, setSelectPersona] = useState(null);
    const [seleccionPersonas, setSeleccionPersonas] = useState([]);
    //OBTENEMOS A TODOS LOS COLABORADORES
    useEffect(
        () => {
            async function getProyectos() {
                let request = new Request("GET", null, 1, "service/getProyectos");
                let response = await request.exec();
                if (response.isSuccess) {
                    setProyectos(response.data);
                }
            }
            getProyectos();
        }, []
    )

    const ChangeProyecto = (value) => {
        setSelectProyecto(value);
        setSeleccionPersonas([]);
        setTodos(false);
        if(value !== null)
            getEmpleadosPorProyecto(value.id_ProyectoEmpresa);
    }

    const getEmpleadosPorProyecto = async(idProyecto) => {
        let request = new Request("GET", null, 1, "service/getEmpleadosProyectos/" +idProyecto);
        let response = await request.exec();
        console.log(response);
        if(response.isSuccess){
            setPersonas(response.data);
        }else{
            alert("Error, no se encontraron colaboradores con relaci+on al proyeto elegido ...");
        }
    }

    const ChangePersona = (arreglo) => {
        if(arreglo !== null){
            setSelectPersona(arreglo);
            setSeleccionPersonas([...seleccionPersonas, arreglo]);
        }
    }

    const ChangeBox = () => {
        if(todos !== true){
            if(selectProyecto === null || selectProyecto === '')
                alert("Para seleccionar a todos los colaboradores ingresa un proyecto");
            else{
                setTodos(!todos);
                setSelectPersona(null);
                setSeleccionPersonas(personas);
            }
        }else{
            setTodos(!todos);
            setSelectPersona(null);
            setSeleccionPersonas([]);
        }
    }

    const DeletePersona = (idPersonal) => {
        setSeleccionPersonas( seleccionPersonas.filter( persona => persona.idPersonal !== idPersonal));
    }

    const ChangeForm = (e) => {
        setForm({...form, [e.target.id] : e.target.value});
    }

    const onSubmit = async()=> {
        let body = {
            proyecto: selectProyecto,
            colaboradores: seleccionPersonas,
            formulario: form
        }
        console.log(body);
        let validar = true;
        if(validar){
            let request = new Request("POST", body, 0, "service/generaContratos");
            let response = await request.blob();
            var file = window.URL.createObjectURL( response );
            window.open( file, "_blank"  );
        }
    }



    return (
        <Contratos classes={classes} form={form} ChangeForm={ChangeForm} proyectos={proyectos} selectProyecto={selectProyecto} ChangeProyecto={ChangeProyecto} personas={personas} selectPersona={selectPersona} ChangePersona={ChangePersona} seleccionPersonas={seleccionPersonas} todos={todos} ChangeBox={ChangeBox} DeletePersona={DeletePersona} onSubmit={onSubmit}/>
    )
}

export default ContratosContainer