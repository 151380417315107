import HOST from '../config';


class Solicitud{
    constructor(method, body, isAuth, url){
        this.method = method;
        this.body = body;
        this.isAuth = isAuth;
        this.url = url;
    }

    async exec() {
        let myHeaders;
        let config;
        let auth = JSON.parse(sessionStorage.getItem('auth'));
        let token = "";
        if( auth != null )
            token = auth.token;
        
        if( this.method === 'GET')
            myHeaders = { 
                'Authorization': 'Bearer ' + token,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            }
        else
            myHeaders = { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer ' + token,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            }
    
        if( this.method === 'GET' )
            config = { method: this.method, headers: myHeaders }
        else
            config = { method: this.method, headers: myHeaders, body: JSON.stringify( this.body ) }
        try{
            let response = await fetch(HOST + this.url, config);
            return await response.json();
        }
        catch(response){
            return {datos:null, esExitosa: false, mensaje: "Error del lado del servidor, puede que no esté disponible. \nFavor de intentar más tarde."};
        }
    }

    async upload(newName) {
        let format = new FormData();
        format.append("file", this.body);
        format.append("newName", newName);
        let configContrato = {
            method: this.method,
            body: format
        }
        try{
            let response = await fetch(HOST + this.url, configContrato);
            return await response.json();
        }
        catch(e){
            alert("Catch error");
        }
    }
}

export default Solicitud;