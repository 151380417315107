import React, { useEffect, useState } from 'react';
import Request from '../../async/request';

const SectionBank = ({idPersonal}) =>{
    const [banco, setBanco] = useState({});

    useEffect(
        () =>{
            async function get(){
                let request = new Request("GET", null, true, "control/empleado_bancario/find/" + idPersonal );
                let response = await request.exec();
                if( response.isSuccess )
                    if( response.data !== null )
                        setBanco( response.data );
            }
            get();
        },[idPersonal]
    )
 
    return(
        <div className="col-md-4 mt-4 text-center">
            <h3>Información Bancaria</h3>
            <div className="content-center mb-4">
                <div className="line-title" />
            </div>
            <p>
                <span>CLABE: </span>
                <span>{banco.clabe ? banco.clabe : "No proporcionado"}</span>
            </p>
            <p>
                <span>Número de cuenta: </span>
                <span>{banco.cuenta ? banco.cuenta : "No proporcionado"}</span>
            </p>
            <p>
                <span>Banco: </span>
                <span>{banco.banco ? banco.banco : "No proporcionado"}</span>
            </p>
        </div>
    )
};

export default SectionBank;