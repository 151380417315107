import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Ingreso from '../pages/Ingreso';
import LoginContainer from '../containers/LoginContainer';
import IndexContainer from '../containers/IndexContainer';
import SolicitudContainer from '../containers/SolicitudContainer';
import PerfilContainer from '../containers/PerfilContainer';
import AtraccionContainer from '../containers/AtraccionContainer';
import ResultadoContainer from '../containers/ResultadoContainer';
import OrganigramaContainer from '../containers/OrganigramaContainer';
import PersonalContainer from '../containers/PersonalContainer';
import CursosContainer from '../containers/CursosContainer';
import ContratosContainer from '../containers/ContratosContainer';
const App = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" >
                    <LoginContainer />
                </Route>
                <Route exact path="/login" >
                    <LoginContainer />
                </Route>
                <Route exact path="/index">
                    <IndexContainer />
                </Route>
                <Route exact path="/solicitudes">
                    <SolicitudContainer />
                </Route>
                <Route exact path="/ingreso/:idProyecto" >
                    <Ingreso />
                </Route>
                <Route exact path="/perfil">
                    <PerfilContainer />
                </Route>
                <Route exact path="/atraccion">
                    <AtraccionContainer />
                </Route>
                <Route exact path="/result/:hash">
                    <ResultadoContainer />
                </Route>
                <Route exact path="/organigrama">
                    <OrganigramaContainer />
                </Route>
                <Route path="/personal">
                    <PersonalContainer />
                </Route>
                <Route path="/cursos">
                    <CursosContainer />
                </Route>
                <Route path="/contratos">
                    <ContratosContainer />
                </Route>
            </Switch>
        </BrowserRouter>

    )
}

export default App;
