import { TextField, Fab } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import useStyles from '../../theme/ThemeInput';
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const FormularioEmergencia = ({btnText="Enviar", form, form2, onChange, onChange2, handleSubmit}) => {
    const classes = useStyles();

    return(
        <Fragment>
            <div className="container-form-rhea">
                <Form onSubmit={handleSubmit} className="mb-5 formulario-rhea">    
                    <p className="text-center mb-4">Contacto 1</p>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-nombre">
                                <TextField className={classes.textField} variant="outlined" label="Nombre del contacto 1" required type="text" name="nombreReferencia" value={form.nombreReferencia} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-celular">
                                <TextField className={classes.textField} variant="outlined" label="Teléfono fijo 1" required type="tel" name="telefono" inputProps={{ pattern: "[0-9]{10}", title:"Sólo debe contener 10 números sin espacio ni guiones", maxLength: 10}} value={form.telefono} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-celular">
                                <TextField className={classes.textField} variant="outlined" label="Celular 1" required type="tel" name="celular" inputProps={{ pattern: "[0-9]{10}", title:"Sólo debe contener 10 números sin espacio ni guiones", maxLength: 10}} value={form.celular} onChange={onChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-correo">
                                <TextField className={classes.textField} variant="outlined" label="Parentesco 1" required type="text" name="parentesco" value={form.parentesco} onChange={onChange}/>
                            </Form.Group>
                        </div>
                    </div>

                    <p className="text-center mb-4">Contacto 2</p>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-nombre">
                                <TextField className={classes.textField} variant="outlined" label="Nombre del contacto 2" required type="text" name="nombreReferencia" value={form2.nombreReferencia} onChange={onChange2} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-celular">
                                <TextField className={classes.textField} variant="outlined" label="Teléfono fijo 2" required type="tel" name="telefono" inputProps={{ pattern: "[0-9]{10}", title:"Sólo debe contener 10 números sin espacio ni guiones", maxLength: 10}} value={form2.telefono} onChange={onChange2} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-celular">
                                <TextField className={classes.textField} variant="outlined" label="Celular 2" required type="tel" name="celular" inputProps={{ pattern: "[0-9]{10}", title:"Sólo debe contener 10 números sin espacio ni guiones", maxLength: 10}} value={form2.celular} onChange={onChange2} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="contacto-correo">
                                <TextField className={classes.textField} variant="outlined" label="Parentesco 2" required type="text" name="parentesco" value={form2.parentesco} onChange={onChange2}/>
                            </Form.Group>
                        </div>
                    </div>
    
                    
    
    
                    <div className="mt-4 text-center">
                        <Fab type="submit" className="button-send" variant="extended">
                            <NavigationIcon />
                            {btnText}
                        </Fab>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default FormularioEmergencia;