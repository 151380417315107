import React, { Fragment } from 'react';
import Logo from '../../images/TRIDENT_GIF.gif';
import { Fab } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';

const CardResumen = ({
    contacto1, 
    contacto2, 
    general, 
    banco, 
    contacto, 
    domicilio,
    rfc,
    curp,
    acta,
    nss,
    ine,
    comprobante,
    estudios,
    cv,
    infonavit,
    fonacot,
    recomendacion,
    estadoCuenta,
    certificaciones,
    doc,
    onSubmit,
    flag=true
}) => {
    const obtenerFecha = (aaaaMMdd) => {
        let separador = aaaaMMdd.split("-");
        return separador[2] + "/" + separador[1] + "/" + separador[0];
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-center">
                { general.img ?
                    <img src={general.img} className="imagen-general" alt="Foto" />
                    :
                    <img src={Logo} className="imagen-general" alt="Foto" />
                }
            </div>
            <div className="row justify-content-center mt-4 pb-5">
                <div className="col-md-4 text-center resumen">
                    <h4>General</h4>
                    <p>
                        <span>Nombre: </span>
                        <span>{general.nombre} {general.apellidoPaterno} {general.apellidoMaterno}</span>
                    </p>
                    <p>
                        <span>CURP: </span>
                        <span>{general.curp}</span>
                    </p>
                    <p>
                        <span>Sexo: </span>
                        <span>{general.sexo}</span>
                    </p>
                    <p>
                        <span>Fecha de nacimiento: </span>
                        <span>{obtenerFecha(general.fechaDeNacimiento)}</span>
                    </p>

                    <p>
                        <span>Estado civil: </span>
                        <span>{general.estadoCivil}</span>
                    </p>
                </div>
                <div className="col-md-4 text-center resumen">
                    <h4>Contacto y domicilio</h4>
                    <p>
                        <span>Teléfono: </span>
                        <span>{contacto.telefono}</span>
                    </p>
                    <p>
                        <span>Celular: </span>
                        <span>{contacto.celular}</span>
                    </p>
                    <p>
                        <span>Correo: </span>
                        <span>{contacto.correo}</span>
                    </p>
                    <p>
                        <span>Estado: </span>
                        <span>{domicilio.estado}</span>
                    </p>
                    <p>
                        <span>Municipio: </span>
                        <span>{domicilio.municipio}</span>
                    </p>
                    <p>
                        <span>Dirección: </span>
                        <span>{domicilio.calle} Int. {domicilio.interior} Ext. {domicilio.exterior} {domicilio.codigoPostal}, {domicilio.colonia}</span>
                    </p>
                </div>
                <div className="col-md-4 text-center resumen">
                    <h4>Información bancaria</h4>
                    <p>
                        <span>CLABE: </span>
                        <span>{banco.clabe}</span>
                    </p>
                    <p>
                        <span>Número de cuenta: </span>
                        <span>{banco.cuenta}</span>
                    </p>
                    <p>
                        <span>Cuentahabiente: </span>
                        <span>{banco.nombre}</span>
                    </p>
                    <p>
                        <span>Banco: </span>
                        <span>{banco.banco}</span>
                    </p>
                </div>
                <div className="col-md-4 mt-3 text-center resumen">
                    <h4>Contacto de emergecia 1</h4>
                    <p>
                        <span>Nombre: </span>
                        <span>{contacto1.nombreReferencia}</span>
                    </p>
                    <p>
                        <span>Teléfono: </span>
                        <span>{contacto1.telefono}</span>
                    </p>
                    <p>
                        <span>Celular: </span>
                        <span>{contacto1.celular}</span>
                    </p>
                    <p>
                        <span>Parentesco: </span>
                        <span>{contacto1.parentesco}</span>
                    </p>
                </div>
                <div className="col-md-4 mt-3 text-center resumen">
                    <h4>Contacto de emergecia 2</h4>
                    <p>
                        <span>Nombre: </span>
                        <span>{contacto2.nombreReferencia}</span>
                    </p>
                    <p>
                        <span>Teléfono: </span>
                        <span>{contacto2.telefono}</span>
                    </p>
                    <p>
                        <span>Celular: </span>
                        <span>{contacto2.celular}</span>
                    </p>
                    <p>
                        <span>Parentesco: </span>
                        <span>{contacto2.parentesco}</span>
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mt-4 mb-5">
                <div className="col-md-4 mt-3 text-center resumen">
                    <h4>Información de documentos</h4>
                    <p>
                        <span>RFC: </span>
                        <span>{doc.rfc}</span>
                    </p>
                    <p>
                        <span>CURP: </span>
                        <span>{doc.curp}</span>
                    </p>
                    <p>
                        <span>NSS: </span>
                        <span>{doc.nss}</span>
                    </p>
                    <p>
                        <span>IDMEX: </span>
                        <span>{doc.numeroINE}</span>
                    </p>
                </div>
                <div className="col-md-4 mt-3 text-center resumen">
                    <h4>Créditos</h4>
                    <p>
                        <span>Crédito INFONAVIT: </span>
                        <span>{doc.numeroINFONAVIT ? doc.numeroINFONAVIT : "No aplica"}</span>
                    </p>
                    <p>
                        <span>Crédito FONACTO: </span>
                        <span>{doc.numeroFONACOT ? doc.numeroFONACOT : "No aplica"}</span>
                    </p>
                </div>
                {flag ?
                    <Fragment>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Constancia de situación fiscal</h4>
                            <div className="container-doc-validate">
                                { rfc ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{rfc.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>CURP</h4>
                            <div className="container-doc-validate">
                                { curp ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{curp.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Acta de nacimiento</h4>
                            <div className="container-doc-validate">
                                { acta ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{acta.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>No de Seguridad Social</h4>
                            <div className="container-doc-validate">
                                { nss ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{nss.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>INE</h4>
                            <div className="container-doc-validate">
                                { ine ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{ine.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Comprobante de domicilio</h4>
                            <div className="container-doc-validate">
                                { comprobante ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{comprobante.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Estado de Cuenta</h4>
                            <div className="container-doc-validate">
                                { estadoCuenta ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{estadoCuenta.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Comprobante de estudios</h4>
                            <div className="container-doc-validate">
                                { estudios ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{estudios.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Curriculum Vitae</h4>
                            <div className="container-doc-validate">
                                { cv ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{cv.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Infonavit</h4>
                            <div className="container-doc-validate">
                                { infonavit ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{infonavit.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Fonacot</h4>
                            <div className="container-doc-validate">
                                { fonacot ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{fonacot.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Cartas de recomendación</h4>
                            <div className="container-doc-validate">
                                { recomendacion ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{recomendacion.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No cargado</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 text-center resumen">
                            <h4>Certificaciones</h4>
                            <div className="container-doc-validate">
                                { certificaciones ?
                                    <div>
                                        <i className="mt-4 fas fa-file-pdf"></i>
                                        <p>{certificaciones.name}</p>
                                    </div>
                                    :
                                    <div>
                                        <i className="mt-4 far fa-folder-open"></i>
                                        <p>No corresponde</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                    :
                    void(0)
                }
            </div>
            { flag ?
                <div className="mt-4 pb-5 container-form-rhea text-center">
                    <Fab type="submit" className="button-send" onClick={onSubmit} variant="extended">
                        <NavigationIcon />
                        Enviar
                    </Fab>
                </div>:void(0)
            }
        </Fragment>
    )
}

export default CardResumen;