import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Request from '../async/request';
import Solicitud from '../async/solicitud';
import Perfil from '../pages/Perfil';

const PerfilContainer = () =>{
    const history = useHistory();
    const [user, setUser] = useState({});
    const [contact, setContact] = useState({});
    const [work, setWork] = useState({});
    const [address, setAddress] = useState({});
    const [foto, setFoto] = useState(null);

    useEffect(
        () =>{
            async function fetchUser(){
                let request = new Request("POST", null, 1, "service/valida");
                let response = await request.exec();
                if( response.isSuccess ){
                    let auth = JSON.parse( sessionStorage.getItem("auth") );
                    request = new Request("GET", null, 1, "control/empleado_general/find/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess ){
                        setUser( response.data );
                    }
                    request = new Request("GET", null, 1, "control/empleado_contacto/find/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess )
                        setContact( response.data );
                    request = new Request("GET", null, 1, "mapper/info_laboral/find/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess )
                        setWork( response.data );
                    request = new Request("GET", null, 1, "control/empleado_domicilio/find/" + auth.idPersonal );
                    response = await request.exec();
                    if( response.isSuccess )
                        setAddress( response.data );
                    request = new Request("POST", null, 1, "service/descarga/foto/" + auth.idPersonal );
                    response = await request.blob();
                    let img = URL.createObjectURL( response );
                    setFoto( img );
                }
                else
                    history.push("/");
            }
            fetchUser();
        },[history]
    );

    const changePhoto = async (e) =>{
        if( e.target.files.length > 0 ){
            let file = e.target.files[0];
            let solicitud = new Solicitud("POST", file, 1, "photos.php");
            let up = await solicitud.upload( user.idPersonal );
            if( up.esExitosa ){
                let request = new Request("POST", null, 1, "service/descarga/foto/" + user.idPersonal );
                let response = await request.blob();
                let img = URL.createObjectURL( response );
                setFoto( img );
            }
        }
    }

    return(
        <Perfil  
            changePhoto={changePhoto}
            user={user} 
            contact={contact} 
            work={work} 
            address={address} 
            foto={foto}
        />
    )
};

export default PerfilContainer;