import React, { Fragment, useEffect, useState } from 'react';
import Steps from '../components/Steps';
import FormularioGeneral from '../components/Forms/FormularioGeneral';
import FormularioContacto from '../components/Forms/FormularioContacto';
import FormularioDireccion from '../components/Forms/FormularioDireccion';
import FormularioDocumentos from '../components/Forms/FormularioDocumentos';
import FormularioBanco from '../components/Forms/FormularioBanco';
import FormularioEmergencia from '../components/Forms/FormularioEmergencia';
import Solicitud from '../async/solicitud';
import states from '../resources/states.json';
import banks from '../resources/bancos.json';
import CardResumen from '../components/Cards/CardResumen';
import Information from '../components/Modal/ModalError';
import hookGeneral from '../hooks/general';
import hookContacto from '../hooks/contacto';
import hookDomicilio from '../hooks/domicilio';
import hookBancario from '../hooks/bancario';
import hookEmergencia from '../hooks/emergencia';
import hookDocumentos from '../hooks/documentos';
import Loading from '../components/Loading';
import Exito from '../pages/Exito';
import Request from '../async/request';
import HookCertificaciones from '../hooks/certificaciones';

const FlujoIngreso = ({idProyecto}) => {
    const [id, setID] = useState("");
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState( false );
    const [transaccion, setTransaccion] = useState("1");
    const [general, setGeneral] = useState({
        img: '',
        activo: 0,
        foto: '/photos/',
        curp: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaDeNacimiento: new Date('2000-01-01').toISOString().substring(0, 10),
        paisDeNacimiento: '',
        estadoDeNacimiento: '',
        estadoCivil: '',
        sexo: '',
    });
    const [contacto, setContacto] = useState({
        telefono: '',
        celular: '',
        correo: ''
    });
    const [file, setFile] = useState();
    const [colonias, setColonias] = useState([]);
    const [contacto1, setContacto1] = useState({
        nombreReferencia: '',
        telefono: '',
        celular: '',
        parentesco: ''
    });
    const [contacto2, setContacto2] = useState({
        nombreReferencia: '',
        telefono: '',
        celular: '',
        parentesco: ''
    });
    const [domicilio, setDomicilio] = useState({
        codigoPostal: '',
        municipio: '',
        estado: '',
        colonia: '',
        calle: '',
        numeroInterior: '',
        numeroExterior: ''
    });
    const [banco, setBanco] = useState({
        banco: "",
        bancoFiscal: "",
        clabe: "",
        cuenta: "",
        nombre: ''
    });
    const [rfc, setRFC] = useState(null);
    const [curp, setCURP] = useState();
    const [acta, setActa] = useState();
    const [nss, setNSS] = useState();
    const [ine, setINE] = useState();
    const [comprobante, setComprobante] = useState();
    const [estudios, setEstudios] = useState();
    const [cv, setCV] = useState();
    const [infonavit, setINFONAVIT] = useState();
    const [fonacot, setFONACOT] = useState();
    const [recomendacion, setRecomendacion] = useState();
    const [estadoCuenta, setEstadoCuenta] = useState();
    const [certificaciones, setCertificaciones] = useState(null);
    const [doc, setDoc] = useState({
        rfc: '',
        curp: '',
        nss: '',
        numeroINE: '',
        numeroINFONAVIT: '',
        numeroFONACOT: '',
        rfcFile: '',
        estudiosFile: '',
        actaFile: '',
        curpFile: '',
        nssFile: '',
        ineFile: '',
        comprobanteFile: '',
        cvFile: '',
        infonavitFile: '',
        fonacotFile: '',
        recomendacionFile: '',
        estadoCuentaFile: ''
    });

    useEffect(
        () => {
            window.scrollTo(0, 0);
        }, [transaccion]
    )

    const onLoad = (e) => {
        const MAX_FILE_SIZE = 5002400;
        if(e.target.files[0].size < MAX_FILE_SIZE && e.target.files[0].type === "application/pdf") {
            setDoc({ ...doc, [e.target.name]: "ok" });
            if (e.target.name === "rfcFile")
                setRFC(e.target.files[0]);
            if (e.target.name === "estudiosFile")
                setEstudios(e.target.files[0]);
            if (e.target.name === "actaFile")
                setActa(e.target.files[0]);
            else if (e.target.name === "curpFile")
                setCURP(e.target.files[0]);
            else if (e.target.name === "nssFile")
                setNSS(e.target.files[0]);
            else if (e.target.name === "ineFile")
                setINE(e.target.files[0]);
            else if (e.target.name === "comprobanteFile")
                setComprobante(e.target.files[0]);
            else if (e.target.name === "cvFile")
                setCV(e.target.files[0]);
            else if (e.target.name === "infonavitFile")
                setINFONAVIT(e.target.files[0]);
            else if (e.target.name === "fonacotFile")
                setFONACOT(e.target.files[0]);
            else if (e.target.name === "recomendacionFile")
                setRecomendacion(e.target.files[0]);
            else if(e.target.name === "estadoCuentaFile")
                setEstadoCuenta(e.target.files[0]);
            else if(e.target.name === "certificaciones")
                setCertificaciones(e.target.files[0]);
        }else{
           alert("El archivo debe pesar menos de 5MB y tiene que ser en formato pdf");
        }
    }

    //! ESTA FUNCION NO SE USA DESDE EL FRONTEND
    //! TAMPOCO EN EL LADO DEL CONTAINER
    const handleSubmitDoc = async (e) => {
        e.preventDefault();
        let id;
        let rfcPath = "";
        let curpPath = "";
        let nssPath = "";
        let inePath = "";
        let comprobantePath = "";
        let cvPath = "";
        let infonavitPath = "";
        let fonacotPath = "";
        let actaPath = "";
        let estudiosPath = "";
        let recomendacionPath = "";
        let solicitud;

        if (rfc != null) {
            solicitud = new Solicitud("POST", rfc, 0, "docs.php/rfc");
            let dataRFC = await solicitud.upload(doc.rfc);
            if (dataRFC.esExitosa)
                rfcPath = dataRFC.datos;
        }
        if (acta != null) {
            solicitud = new Solicitud("POST", acta, 0, "docs.php/acta");
            let dataActa = await solicitud.upload(doc.rfc);
            if (dataActa.esExitosa)
                actaPath = dataActa.datos;
        }
        if (recomendacion != null) {
            solicitud = new Solicitud("POST", recomendacion, 0, "docs.php/recomendacion");
            let dataPsicometrico = await solicitud.upload(doc.rfc);
            if (dataPsicometrico.esExitosa)
                recomendacionPath = dataPsicometrico.datos;
        }
        if (estudios != null) {
            solicitud = new Solicitud("POST", estudios, 0, "docs.php/estudios");
            let dataEstudios = await solicitud.upload(doc.rfc);
            if (dataEstudios.esExitosa)
                estudiosPath = dataEstudios.datos;
        }
        if (curp != null) {
            solicitud = new Solicitud("POST", curp, 0, "docs.php/curp");
            let dataCURP = await solicitud.upload(doc.curp);
            if (dataCURP.esExitosa)
                curpPath = dataCURP.datos;
        }
        if (nss != null) {
            solicitud = new Solicitud("POST", nss, 0, "docs.php/nss");
            let dataNSS = await solicitud.upload(doc.rfc);
            if (dataNSS.esExitosa)
                nssPath = dataNSS.datos;
        }
        if (ine != null) {
            solicitud = new Solicitud("POST", ine, 0, "docs.php/ine");
            let dataINE = await solicitud.upload(doc.rfc);
            if (dataINE.esExitosa)
                inePath = dataINE.datos;
        }
        if (comprobante) {
            solicitud = new Solicitud("POST", comprobante, 0, "docs.php/comprobante");
            let dataComprobante = await solicitud.upload(doc.rfc);
            if (dataComprobante.esExitosa)
                comprobantePath = dataComprobante.datos;
        }
        if (cv != null) {
            solicitud = new Solicitud("POST", cv, 0, "docs.php/cv");
            let dataCV = await solicitud.upload(doc.rfc);
            if (dataCV.esExitosa)
                cvPath = dataCV.datos;
        }

        if (infonavit != null) {
            solicitud = new Solicitud("POST", infonavit, 0, "docs.php/infonavit");
            let dataINFONAVIT = await solicitud.exec(doc.rfc);
            if (dataINFONAVIT.esExitosa)
                infonavitPath = dataINFONAVIT.datos;
        }

        if (fonacot != null) {
            solicitud = new Solicitud("POST", fonacot, 0, "docs.php/fonacot");
            let dataFONACOT = await solicitud.upload(doc.rfc);
            if (dataFONACOT.esExitosa)
                fonacotPath = dataFONACOT.datos;
        }

        let body = {
            empleado_documentos: {
                idPersonal: id,
                rfcPath: rfcPath,
                curpPath: curpPath,
                nssPath: nssPath,
                INEPath: inePath,
                comprobanteDomicilio: comprobantePath,
                cv: cvPath,
                INFONAVITPath: infonavitPath,
                FONACOTPath: fonacotPath,
                actaPath: actaPath,
                estudiosPath: estudiosPath,
                recomendacionPath: recomendacionPath,
                ...doc
            }
        }
        solicitud = new Solicitud("POST", body, 1, "empleado_documentos.php");
        let data = await solicitud.exec();
        if (data.esExitosa) {

        }
    }

    //! NO SE USA DESDE EL LADO DEL FRONTEND
    //! TAMPOCO EN EL LADO DEL CONTAINER
    const onChangeDoc = (e) => {
        setDoc({ ...doc, [e.target.name]: e.target.value.toUpperCase() });
    }

    const onChangeContacto1 = (e) => setContacto1({ ...contacto1, [e.target.name]: e.target.value.toUpperCase() });
    const onChangeContacto2 = (e) => setContacto2({ ...contacto2, [e.target.name]: e.target.value.toUpperCase() });

    const onChangeDomicilio = e => {
        let info = [];
        let estado_aux = "";
        let municipio_aux = "";
        states.forEach(state => {
            if (state.cp === parseInt(e.target.value, 10)) {
                municipio_aux = state.municipio;
                estado_aux = state.estado;
                info.push(state.asentamiento);
            }
        });
        setColonias(info);
        setDomicilio({ ...domicilio, [e.target.name]: e.target.value.toUpperCase(), "estado": estado_aux, "municipio": municipio_aux, "colonia": '' })
    }

    const onChangeAttribDomicilio = e => setDomicilio({ ...domicilio, [e.target.name]: e.target.value.toUpperCase() });

    const onChangeGeneral = e => {
        if (e.target.files != null) {
            setFile(e.target.files[0]);
            let reader = new FileReader();
            try {
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = (e) => {
                    setGeneral({ ...general, "img": e.target.result });
                }
            } catch (error) {
                console.log(error);
            }
        }
        else
            setGeneral({ ...general, [e.target.name]: e.target.value.toUpperCase() });
    }

    const onChangeContacto = (e) => setContacto({ ...contacto, [e.target.name]: e.target.value.toUpperCase() });

    const onChangeBanco = (e) => setBanco({ ...banco, [e.target.name]: e.target.value.toUpperCase() });

    const onChangeClabe = e => {
        let str = e.target.value.toUpperCase();
        let name = banco.banco;
        let fiscal = banco.bancoFiscal;
        if (str.length === 3)
            banks.forEach(bank => {
                if (str === "" + bank.clabe) {
                    name = bank.marca;
                    fiscal = bank.nombre;
                }
            });
        setBanco({ ...banco, [e.target.name]: e.target.value.toUpperCase(), "banco": name, "bancoFiscal": fiscal });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let state = parseInt(transaccion) + 1;
        setTransaccion("" + state);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const generales = [["img", "una foto"], ["nombre", "su nombre"], ["apellidoPaterno", "su apellido paterno"], ["apellidoMaterno", "su apellido materno"], ["curp", "su CURP"], ["sexo", "su sexo"], ["estadoCivil", "su estado civil"], ["fechaDeNacimiento", "su Fecha de Nacimiento"], ["paisDeNacimiento", "su país de nacimiento"], ["estadoDeNacimiento", "su estado de nacimiento"]];
        const contactos = [["celular", "su celular"], ["correo", "su correo personal"]];
        const domicilios = [["codigoPostal", "su código postal"], ["colonia", "su colonia"], ["calle", "su calle"], ["numeroInterior", "su número interior o en caso de no tener colocar S/N"], ["numeroExterior", "su número interior o en caso de no tener colocar S/N"]]
        const bancarios = [["clabe", "su CLABE"], ["cuenta", "su número de cuenta"]]
        const emergencia = [["nombreReferencia", "el nombre de un contacto de emergencia"], ["telefono", "un número fijo de su contacto de referencia"], ["celular", "un celular de su contacto de referencia"], ["parentesco", "el parentesco de su contacto de referencia"]]
        const documentos = [["rfc", "su RFC"], ["curp", "su CURP"], ["nss", "su Número de Seguridad Social"], ["rfcFile", "/ subir su constancia de situación fiscal"], ["estudiosFile", "/ subir su comprobante de estudios"], ["actaFile", "/ subir su acta de nacimiento"], ["curpFile", "/ subir su CURP en PDF"], ["nssFile", "/ subir su documento de alta en IMSS"], ["ineFile", "/ subir su identificación escaneada"], ["comprobanteFile", "/ subir su comprobante de domicilio"], ["cvFile", "/ subir su Curriculum Vitae"], ["recomendacionFile", "/ subir sus cartas de recomendación escaneadas en un sólo archivo"], ["estadoCuentaFile", "/ subir tu estado de cuenta "]]
        if (validate(generales, general))
            if (validate(contactos, contacto))
                if (validate(domicilios, domicilio))
                    if (validate(bancarios, banco))
                        if (validate(emergencia, contacto1))
                            if (validate(emergencia, contacto2))
                                if (validate(documentos, doc))
                                    await hookAll();
        
        /*proyectoAICM(id,doc.rfc,certificaciones);*/
    }

    const hookAll = async () => {
        setLoading( true );
        let response = await hookGeneral(general, file);
        if (response.esExitosa) {
            let id = response.datos.idPersonal;
            setID( id );
            response = await hookContacto(id, contacto);
            console.log( response );
            response = await hookDomicilio(id, domicilio);
            console.log( response );
            response = await hookBancario(id, banco);
            console.log( response );
            response = await hookEmergencia(id, contacto1);
            console.log( response );
            response = await hookEmergencia(id, contacto2);
            console.log( response );
            response = await hookDocumentos(
                id,
                doc,
                rfc,
                curp,
                acta,
                nss,
                ine,
                comprobante,
                estudios,
                cv,
                infonavit,
                fonacot,
                recomendacion,
                estadoCuenta
            );
            switch (idProyecto) {
                case '1':
                    proyectoAICM(id,doc.rfc,certificaciones);
                break;
                default:
                    console.log("ingreso - normal");
                break;
            }
            setTimeout(
                () =>{
                    setLoading( false );
                    setTransaccion("8");
                }, 1000
            );
            console.log( response );
            //TODO: RELACION PROYECTO - EMPLEADO
            let rel_body = {idPersonal: id, id_ProyectoEmpresa: idProyecto};
            let rel_request = new Request("POST", rel_body, 1, "service/rel_proyectoEmpleado" );
            let rel_response = await rel_request.exec();
            if(rel_response.isSuccess === true)
                console.log("Persona-proyecto");
            //TODO: CORREO INFORMATIVO
            let body = { idPersonal: id, nombre: general.nombre + " " + general.apellidoPaterno + " " + general.apellidoMaterno };
            let request = new Request("POST", body, 1, "service/correo/ingreso" );
            response = await request.exec();
        }else{
            setTimeout(
                () =>{
                    setLoading( false );
                }, 2000
            );
        }
    }
    
    const proyectoAICM = async(id, rfc, certificaciones) => {
        //Obtenemos toda la parte derl documento
        if(certificaciones !== null){
            let response = await HookCertificaciones(id,rfc,certificaciones);
            console.log(response);
        }else
            alert("Selecciona un archivo, ajuntando las certificaciones");
    }

    const validate = (array, dict) => {
        let element = null;
        for (let i = 0; i < array.length; i++) {
            element = array[i];
            if (!dict[element[0]]) {
                setTitle("¡Atención!");
                setBody("Debe ingresar " + element[1] + " para poder continuar");
                setShow(true);
                return false;
            }
        }
        return true;
    }

    const onClick = (e) => setTransaccion(e.target.id);

    const ocultarModal = () => setShow(false);

    if (transaccion === "1")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={1} />
                    <h3 className="mt-4 mb-4 text-center">Datos generales</h3>
                    <FormularioGeneral btnText="Continuar" handleSubmit={handleSubmit} onChange={onChangeGeneral} form={general} />
                </div>
            </Fragment>
        )
    else if (transaccion === "2")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={2} />
                    <h3 className="mt-4 mb-4 text-center">Datos de contacto</h3>
                    <FormularioContacto btnText="Continuar" handleSubmit={handleSubmit} onChange={onChangeContacto} form={contacto} />
                </div>
            </Fragment>
        )
    else if (transaccion === "3")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={3} />
                    <h3 className="mt-4 mb-4 text-center">Datos de domicilio</h3>
                    <FormularioDireccion btnText="Continuar" handleSubmit={handleSubmit} form={domicilio} colonias={colonias} onChange={onChangeDomicilio} onChangeAttrib={onChangeAttribDomicilio} />
                </div>
            </Fragment>
        )
    else if (transaccion === "4")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={4} />
                    <h3 className="mt-4 mb-4 text-center">Datos bancarios</h3>
                    <FormularioBanco btnText="Continuar" handleSubmit={handleSubmit} form={banco} onChangeClabe={onChangeClabe} onChange={onChangeBanco} />
                </div>
            </Fragment>
        )
    else if (transaccion === "5")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={5} />
                    <h3 className="mt-4 mb-4 text-center">Datos de emergencia</h3>
                    <FormularioEmergencia btnText="Continuar" handleSubmit={handleSubmit} form={contacto1} form2={contacto2} onChange={onChangeContacto1} onChange2={onChangeContacto2} />
                </div>
            </Fragment>
        )
    else if (transaccion === "6")
        return (
            <Fragment>
                <div className="container">
                    <Steps onClick={onClick} step={6} />
                    <h3 className="mt-4 mb-4 text-center">Documentos</h3>
                    <FormularioDocumentos
                        btnText="Continuar"
                        handleSubmit={handleSubmit}
                        form={doc}
                        onChange={onChangeDoc} //! NO SE USA
                        handleSubmitDoc={handleSubmitDoc} //! NO SE USA
                        onLoad={onLoad}
                        rfc={rfc}
                        curp={curp}
                        acta={acta}
                        nss={nss}
                        ine={ine}
                        comprobante={comprobante}
                        estudios={estudios}
                        cv={cv}
                        infonavit={infonavit}
                        fonacot={fonacot}
                        recomendacion={recomendacion}
                        estadoCuenta={estadoCuenta}
                        certificaciones={certificaciones}
                        idProyecto={idProyecto}
                    />
                </div>
            </Fragment>
        )
    else if( transaccion === "7")
        return (
            <Fragment>
                {
                    loading ? 
                        <Loading />
                        :
                        <div className="container">
                            <Steps onClick={onClick} step={7} />
                            <Information mensaje={body} title={title} mostrar={show} ocultarModal={ocultarModal} />
                            <h3 className="mt-4 mb-4 text-center">Resumen</h3>
                            <CardResumen
                                contacto1={contacto1}
                                contacto2={contacto2}
                                banco={banco}
                                contacto={contacto}
                                general={general}
                                domicilio={domicilio}
                                rfc={rfc}
                                curp={curp}
                                acta={acta}
                                nss={nss}
                                ine={ine}
                                comprobante={comprobante}
                                estudios={estudios}
                                cv={cv}
                                infonavit={infonavit}
                                fonacot={fonacot}
                                recomendacion={recomendacion}
                                estadoCuenta={estadoCuenta}
                                certificaciones={certificaciones}
                                doc={doc}
                                onSubmit={onSubmit}
                            />
                        </div>
                }
            </Fragment>
        )
    else
        return(
            <Fragment>
                <Exito id={id} general={general} />
                <div className="container">
                    <CardResumen
                        contacto1={contacto1}
                        contacto2={contacto2}
                        banco={banco}
                        contacto={contacto}
                        general={general}
                        domicilio={domicilio}
                        rfc={rfc}
                        curp={curp}
                        acta={acta}
                        nss={nss}
                        ine={ine}
                        comprobante={comprobante}
                        estudios={estudios}
                        cv={cv}
                        infonavit={infonavit}
                        fonacot={fonacot}
                        recomendacion={recomendacion}
                        estadoCuenta={estadoCuenta}
                        doc={doc}
                        onSubmit={onSubmit}
                        flag={false}
                    />
                </div>
            </Fragment>
        )
}

export default FlujoIngreso;