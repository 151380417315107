import React, { Fragment, useEffect, useState } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Solicitud from '../async/solicitud';
import FlujoIngreso from '../flows/FlujoIngreso';
import HOST from '../host';
import NavRHEA from '../components/Others/NavRHEA';
import {useParams} from "react-router-dom";
const Ingreso = () => {
    let { idProyecto } = useParams();
    const [iniciar, setIniciar] = useState( false );
    const [term, setTerm] = useState( false );

    useEffect(
        () =>{
            const fetchInvitado = async () =>{
                console.log(idProyecto);
                let body = { usuario: {correo: 'invitado', pass:'somosrhea'} }
                let solicitud = new Solicitud("POST", body, 0, "autentica.php");
                let data = await solicitud.exec();
                if( data.esExitosa )
                    sessionStorage.setItem('auth', JSON.stringify(data.datos));
            }
            fetchInvitado();
        },[idProyecto]
    )

    const iniciarFlujo = (e) =>  setIniciar( true );

    if( !iniciar )
        return (
            <Fragment>
                <div className="container-principal">
                    <NavRHEA menu={false} />
                    <Info term={term} setTerm={setTerm} iniciarFlujo={iniciarFlujo} />
                </div>
            </Fragment>
        ) 
    return(
        <Fragment>
            <div className="container-principal">
                <NavRHEA menu={false} />
                <div className="info-container">
                    <FlujoIngreso idProyecto={idProyecto} />
                </div>
            </div>
        </Fragment>
    )
}

const Info = ({iniciarFlujo, term, setTerm}) => (
    <Fragment>
        <div className="mt-5 font-weight-bold">
            <h1 className="text-center">Formulario de Ingreso</h1>
        </div>
        <div className="container font-rhea-futura mt-2 mb-5">
            <h2 className="text-center mb-3">Instrucciones</h2>

            <p className="font-weight-bold">
                Bienvenido a RHEA Capital Humano! Ahora es momento que formalizar tu ingreso, 
                por favor es muy importante que tengas a la mano y en forma digital (PDF) los 
                siguientes documentos:
            </p>

            <ul className="font-weight-bold">
                <li>CV actualizado</li>
                <li>Acta de nacimiento</li>
                <li>Acta de matrimonio (en caso de que aplique)</li>
                <li>Comprobante de estudios (título o cédula profesional)</li>
                <li>RFC (Constancia de situación fiscal)</li>
                <li>CURP</li>
                <li>NSS (IMSS)</li>
                <li>INE</li>
                <li>Comprobante de domicilio (no mayor a 2 meses de antigüedad)</li>
                <li>Hoja de retención de crédito INFONAVIT (si aplica)</li>
                <li>Crédito FONACOT (si aplica)</li>
                <li>2 Cartas de Recomendación</li>
                <li>Constancias de cursos realizados (si aplica)</li>
                <li>Copia de contrato de cuenta bancaria o estado de cuenta donde se aprecie 
                    la CLABE interbancaria y el número de cuenta
                </li>
            </ul>

            <p className="font-weight-bold">
                En caso de no contar con toda tu documentación digitalizada, te recomendamos obtenerla antes
                de comenzar este proceso ya que no podrás ingresar nuevamente.
            </p>
            
            <p className="font-weight-bold">
                Durante los formularios, los datos marcados con asterisco (*) son obligatorios
            </p>
            
            <p className="font-weight-bold">
                En los siguientes enlaces podrás adquirir parte de la documentación solicitada:
            </p>
            <ul>
                <li><a className="orangeDot font-weight-bold" href="https://www.gob.mx/curp/" rel="noopener noreferrer" target="_blank">Obten aquí tu CURP</a></li>
                <li><a className="orangeDot font-weight-bold" href="https://www.sat.gob.mx/aplicacion/login/43824/reimprime-tus-acuses-del-rfc" rel="noopener noreferrer" target="_blank">Obten aquí tu constancia de situación fiscal</a></li>
                <li><a className="orangeDot font-weight-bold" href="http://www.imss.gob.mx/derechoH/nss" rel="noopener noreferrer" target="_blank">Obten aquí tu NSS</a></li>
                <li><a className="orangeDot font-weight-bold" href="https://micuenta.infonavit.org.mx/" rel="noopener noreferrer" target="_blank">Obten aquí tu estado de cuenta INFONAVIT</a></li>
                <li><a className="orangeDot font-weight-bold" href="https://servicios.fonacot.gob.mx/" rel="noopener noreferrer" target="_blank">Obten aquí tu estado de cuenta FONACOT</a></li>
            </ul>

            <p className="font-weight-bold">
                Una vez contestado el formulario, te llegará un correo confirmando la recepción 
                de tu información junto con un ID personal que deberás guardar y presentar el 
                día de tu ingreso.
            </p>

            <div className="text-center mt-4">
                <p className="font-weight-bold">Te recordamos que puedes leer nuestro 
                    <a target="_blank" href={HOST + "/docs/aviso.pdf"} rel="noopener noreferrer" className="orangeDot ml-1 mr-1 font-weight-bold" >Aviso de privacidad</a>
                    y para continuar debes aceptar los términos y condiciones.
                </p>
                <div className="text-center">
                    <Checkbox
                        checked={term}
                        onChange={() => setTerm( ! term )}
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <p className="orangeDot font-weight-bold">Acepto los términos y condiciones</p>
                </div>
            </div>

            {
                term ?
                    <Fragment>
                         <div className="mt-4 text-center">
                            <Button onClick={iniciarFlujo} name="1" className="button-send button-upload p-3" >
                                <FileCopyIcon className="mr-3 font-rhea-futura" />
                                Iniciar registro
                            </Button>
                        </div>
                    </Fragment>
                    :
                    void(0)
            }
        </div>
    </Fragment>
)

export default Ingreso;