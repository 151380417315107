import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalConfirmacion = ({ mostrar, ocultarModal, callBack, mensaje, title="¡Confirma la operación!" }) => {
    return (
        <Fragment>
            <Modal show={mostrar} onHide={ocultarModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div>
                        <h3 className="text-center mt-4 mb-3">{title}</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <h5 className="text-justify mb-4">{mensaje}</h5>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <button onClick={callBack} className="button-oma">Si</button>
                            </div>
                            <div className="col-md-4">
                                <button onClick={ocultarModal} className="button-oma">No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default ModalConfirmacion;