import React, { Fragment } from 'react';

const Exito = ({id, general}) =>{

    return(
        <Fragment>
            <div className="container mt-5">
                <h5 className="text-center">Hola</h5>
                <h4 className="text-center">{general.nombre} {general.apellidoPaterno}</h4>
            </div>
            <div className="container pt-3">
                    <h4 className="text-center">¡Tu registro fue éxitoso!</h4>
                    <h4 className="text-center">Tu ID es: {id}</h4>
                    <p className="text-center">El siguiente es un resumen de la información capturada. Si requieres algún cambio comunícate con nosotros.</p>
            </div>
        </Fragment>
    )
}

export default Exito;