import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Resultado from '../pages/Resultado';
import Request from '../async/request';

const ResultadoContainer = () =>{
    const { hash } = useParams();
    const [operacion, setOperacion] = useState();
    const [solicitud, setSolicitud] = useState({});
    const [persona, setPersona] = useState({nombre:'', apellidoPaterno:''});
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);
    const [show,setShow] = useState(false);

    useEffect(
        () =>{
            async function fetchData(){
                let body = { hash: hash };
                let request = new Request("POST", body, 1, "mapper/hash");
                let response = await request.exec();
                if( response.isSuccess ){
                    let data = response.data;
                    setOperacion( data.operacion );
                    request = new Request("GET", null, 1, "control/solicitud_vacaciones/find/" + data.idSolicitud );
                    response = await request.exec();
                    if( response.isSuccess ){
                        setSolicitud( response.data );
                        if( response.data.estatus !== "Pendiente" ){
                            setShow( true );
                            setTitle( "Ya respondiste esta solicitud" );
                            setBody( "La solicitud tiene un estatus de " + String( response.data.estatus ).toUpperCase() );
                        }
                        data = response.data;
                        request = new Request("GET", null, 1, "control/empleado_general/find/" + data.idPersonal );
                        response = await request.exec();
                        setPersona( response.data );
                    }
                }
            }
            fetchData();
        },[hash]
    );

    const onClick = async () =>{
        setLoading( true );
        let request = new Request("GET", null, 1, "control/empleado_laboral/find/" + solicitud.idPersonal );
        let response = await request.exec();
        let correoEmpresarial = response.data.correoEmpresarial;
        let body = {
            correo: correoEmpresarial,
            nombre: persona.nombre + " " + persona.apellidoPaterno,
            ...solicitud
        }
        let estatus;
        let mail;
        let bMsg;
        if( operacion ){
            estatus = 'Autorizada';
            mail = 'aceptarVacaciones';
            bMsg = 'autorizado';

        }
        else{
            estatus = 'Rechazada';
            mail = 'rechazarVacaciones';
            bMsg = 'rechazado';
        }
        let nombre = persona.nombre + " " + persona.apellidoPaterno;
        let update = {
            idSolicitud: solicitud.idSolicitud,
            attribute: 'estatus',
            value: estatus,
        }
        request = new Request("PUT", update, 1, "control/solicitud_vacaciones" );
        response = await request.exec();
        if( response.isSuccess ){
            request = new Request("POST", body, 1, "service/correo/" + mail );
            response = await request.exec();
            if( solicitud.tipo === "Vacaciones" )
                setTitle( "Solicitud " + bMsg );
            else
                setTitle( solicitud.tipo + " " + estatus );
            setBody("Haz " + bMsg +  " la solicitud de " + nombre);
        }
        else{
            setTitle("Algo salió mal");
            setBody("Al parecer algo salió mal. Comunícate con el administrador del sistema o intenta más tarde.");
        }
        request = new Request("GET", null, 1, "control/solicitud_vacaciones/find/" + solicitud.idSolicitud );
        response = await request.exec();
        if( response.isSuccess )
            setSolicitud( response.data );
        setLoading( false );
        setShow( true );
    }

    const onClose = () => setShow( false );

    return(
        <Resultado 
            show={show}
            title={title} 
            body={body} 
            onClick={onClick} 
            onClose={onClose} 
            persona={persona} 
            operacion={operacion} 
            solicitud={solicitud} 
            loading={loading}
        />
    )
};

export default ResultadoContainer;