import React, { useEffect, useState } from 'react';
import Request from '../../async/request';
import CardSolicitud from '../Cards/CardSolicitud';

const SectionVacaciones = ({idPersonal}) => {
    const [info, setInfo] = useState({disponibles:0});
    const [solicitudes, setSolicitudes] = useState([]);
    const [item, setItem] = useState(0);

    useEffect(
        () =>{
            async function get(){
                let request = new Request("POST", null, 1, "service/vacaciones/" + idPersonal );
                let response = await request.exec();
                if( response.isSuccess )
                    setInfo( response.data );
                request = new Request("GET", null, 1, "control/solicitud_vacaciones/id/" + idPersonal );
                response = await request.exec();
                if( response.isSuccess )
                    setSolicitudes( response.data );
            }
            get();
        },[idPersonal]
    );

    const changeItem = (e) =>{
        if( e.target.id === "0" ){
            if( !(item + 1 > (solicitudes.length - 1)) )
                setItem( item + 1 );
        }
        else{
            if( item + 1 > 1 )
                setItem( item - 1 );
        }
    }

    return (
        <div className="mt-4 col-md-4 text-center">
            <h3>Días disponibles de vacaciones</h3>
            <div className="content-center mb-4">
                <div className="line-title" />
            </div>
            <div className="d-flex justify-content-center">
                <div className="days-avatar">
                    <p>{Math.trunc( info.disponibles )}</p>
                    <p>días</p>
                </div>
            </div>
            {
                solicitudes.length > 0 ?
                    <div className="mt-4">
                        <h5>Última solicitud</h5>
                        <div className="content-center mb-4">
                            <div className="line-title" />
                        </div>
                        <div className="iterator-container d-flex mt-4 mb-4 flex-row justify-content-center align-items-center">
                            <i id="1" onClick={changeItem} className="fas fa-chevron-left mr-4"></i>
                            <p>{item + 1} de {solicitudes.length}</p>
                            <i id="0" onClick={changeItem} className="fas fa-chevron-right ml-4"></i>
                        </div>
                        <CardSolicitud solicitud={solicitudes[item]} />
                    </div>
                    :
                    <div className="b-sol mt-4">
                        <p>No tienes solicitudes registradas</p>
                    </div>
            }
        </div>
    )
};

export default SectionVacaciones;