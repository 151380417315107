import React, { Fragment, useEffect, useState } from 'react';
import Request from '../../async/request';
import DateFormatFix from '../../utils/DateFormatFix';

const SectionUser = ({ idPersonal }) => {
    const [user, setUser] = useState({});
    const [contact, setContact] = useState({});

    useEffect(
        () =>{
            async function get() {
                let request = new Request("GET", null, true, "control/empleado_general/find/" + idPersonal );
                let response = await request.exec();
                if( response.isSuccess )
                    setUser( response.data );
                request = new Request("GET", null, true, "control/empleado_contacto/find/" + idPersonal );
                response = await request.exec();
                if( response.isSuccess )
                    setContact( response.data );
            }
            get();
        },[idPersonal]
    )

    return (
        <Fragment>
            <div className="text-center mt-4 col-md-4">
                <h3>Información General</h3>
                <div className="content-center mb-4">
                    <div className="line-title" />
                </div>
                <p>ID: {user.idPersonal ? user.idPersonal : "No definido"}</p>
                <p>Nombre: {user.nombre ? user.nombre : ""} {user.apellidoPaterno ? user.apellidoPaterno : ""} {user.apellidoMaterno ? user.apellidoMaterno : ""}</p>
                <p>CURP: {user.curp ? user.curp : ""}</p>
                <p>Fecha de nacimiento: {user.fechaDeNacimiento ? new DateFormatFix(new Date(user.fechaDeNacimiento + "T00:00:00")).getFormatName() : ""}</p>
                <p>Estado civil: {user.estadoCivil ? user.estadoCivil : ""}</p>
                <h3 className="mt-3">Información de contacto</h3>
                <div className="content-center mb-4">
                    <div className="line-title" />
                </div>
                <p>Teléfono: {contact.telefono ? contact.telefono : "No proporcionado"}</p>
                <p>Celular: {contact.celular ? contact.celular : "No proporcionado"}</p>
                <p>Correo Personal: {contact.correo ? contact.correo.toLowerCase() : "No proporcionado"}</p>
            </div>
        </Fragment>
    )
};

export default SectionUser;