import React, { Fragment } from 'react';
import Spinner from 'react-bootstrap/Spinner';
//import Logo from '../../images/rheach.png';
import Logo from '../../images/logo_atlantisba.png';
import Version from '../../version';

const FormularioLogin = ({ handleSubmit, hiddenPassword, onChange, msg, loading }) => {
    return (
        <Fragment>
            <div className="new-container-form">
                <div className="new-form">
                    <img src={Logo} alt="Logo RHEA" className="imagen-login" />
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label className="form-label" htmlFor="correo">Correo o ID:</label>
                            <input id="correo"  className="form-control control-login" required name="correo" autoComplete="off" onChange={onChange} placeholder="Ingresa tu correo o id" />
                        </div>

                        <div className="form-group" >
                            <label className="form-label" htmlFor="pass">Contraseña:</label>
                            <input id="pass" className="form-control control-login" required name="pass" onChange={onChange} type="password" placeholder="Ingresa tu contraseña" />
                            <i onClick={hiddenPassword} className="icon-password fas fa-eye"></i>
                            <p className="mt-3 text-muted">
                                <a className="link-rhea" href="#hola">Olvidé mi contraseña</a>
                            </p>
                        </div>
                        {
                            loading ?
                                <div className="text-right mt-3">
                                    <Spinner animation="grow" />
                                </div>
                                :
                                <div className="text-right mt-3">
                                    <p className="text-muted mb-2">{msg}</p>
                                    <button className="btn-rhea" type="submit">
                                        Iniciar sesión
                                    </button>
                                    <p className="text-transactions text-white mt-2">Versión: {Version}</p>
                                </div>
                        }
                    </form>
                </div>
            </div>
        </Fragment>
    )

}

export default FormularioLogin;