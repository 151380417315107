import React from 'react';
import NavRHEA from '../components/Others/NavRHEA';
import Notification from '../components/Others/Notification';
import Profile from '../components/Sections/Profile';

const Perfil = ({
    user, 
    contact, 
    work, 
    address, 
    changePhoto,
    foto
}) => (
    <div className="container-principal">
        <div className="info-container">
            <Profile changePhoto={changePhoto} foto={foto} user={user} contact={contact} work={work} address={address} />
        </div>
        <NavRHEA />
        <Notification />
    </div>
);

export default Perfil;